.content {
  max-width: 1184px;
  margin: 0 auto;
  padding: 20px 15px;
  font-size: 16px
}

.content a {
  color: #00f;
  -webkit-transition: color .1s linear;
  -o-transition: color .1s linear;
  transition: color .1s linear
}

.content a:hover, .content h1 a:hover, .content h2 a:hover, .content h3 a:hover, .content h4 a:hover, .content h5 a:hover, .content h6 a:hover {
  color: #7b68ee
}

.content h1, .content h2, .content h3, .content h4, .content h5, .content h6 {
  margin-bottom: .5em
}

.content h1 a, .content h2 a, .content h3 a, .content h4 a, .content h5 a, .content h6 a {
  color: inherit
}

.content h1 {
  font-size: 28px
}

.content h2 {
  font-size: 26px
}

.content h3 {
  font-size: 24px
}

.content h4 {
  font-size: 22px
}

.content h5 {
  font-size: 20px
}

.content input:not([type=submit]), .content textarea {
  margin-top: 5px;
  margin-bottom: 10px
}

.content input[type=text], .content input[type=password], .content input[type=tel], .content input[type=email], .content input[type=number], .content input[type=search], .content textarea {
  display: block;
  width: 100%;
  border: 1px solid #090509;
  border-radius: 19px;
  outline: 0;
  -webkit-transition: border .1s linear;
  -o-transition: border .1s linear;
  transition: border .1s linear;
  color: #333;
  font-size: 21px;
  font-weight: 700;
  padding: 10px;
  height: 45px
}

@media (max-width: 1023px) {
  .content input[type=text], .content input[type=password], .content input[type=tel], .content input[type=email], .content input[type=number], .content input[type=search], .content textarea {
      font-size: 18px;
      height: 30px;
      padding: 4px
  }
}

.content textarea {
  height: 150px;
  resize: none
}

.content .attachment-full, .content ul {
  margin-bottom: 20px
}

.content li {
  margin-bottom: 5px
}

.content .type-cards {
  text-align: center;
  padding: 5px;
  margin-bottom: 20px;
  border-radius: 5px;
  background-color: #fff
}

.content .type-cards img {
  display: block;
  margin: 0 auto 20px
}

.content .type-cards ul {
  margin-bottom: 0
}

.content .type-page {
  margin-bottom: 30px;
  font-size: 18px
}