.details-block .music-holder {
  div.popup-block {
    transition: none;
    label {
      overflow: hidden;
      .calc-width {
        opacity: 0;
        position: absolute;
        z-index: -1;
        pointer-events: none;
      }
    }
    h3 {
      font-family: Roboto;
    }
  }
  .folder-title {
    border-bottom: 1px solid #deb372;
    padding-bottom: 4px;
    margin: 10px 0 20px;
    font-size: 18px;
    font-weight: 500;
    font-family: Roboto;
  }
  .popup-list {
    max-height: 330px;
    overflow-y: auto;
    padding-right: 8px;
    &::-webkit-scrollbar {
      width: 8px
    }

    &::-webkit-scrollbar-track {
      background-color: #dadada;
      border-radius: 10px
    }

    &::-webkit-scrollbar-thumb {
      background-color: #636363;
      border-radius: 10px;
      background-clip: padding-box;
      border: 2px solid transparent
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: #555
    }

  }
  .audio-list {
    max-height: none;
  }
}