@media (max-width: 767px) {
  div.section-heading {
    margin-bottom: 35px;
    .section-title {
      font-size: 38px;
    }
    .heading-note {
      line-height: 1.14;
    }
  }
  section.categories-list-section {
    padding-top: 59px;
    padding-bottom: 24px;
  }
}