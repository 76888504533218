.search {
  position: relative;
  &:not(.opened) {
    .search-input {
      opacity: 0;
      visibility: hidden;
    }
  }
  .search-input {
    border: 1px solid #898989;
    border-radius: 23px;
    padding: 7px 40px 7px 16px;
    background-color: transparent;
    height: 33px;
    width: 224px;
    color: #000;
    @media (max-width: 1023px) {
      width: 217px;
      height: 37px;
    }
    &::placeholder {
      opacity: 0.5;
      color: #898989;
    }
  }
  .search-submit {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: transparent;
    border: none;
    right: 13px;
    padding: 0;
    svg {
      width: 15px;
      height: 15px;
      fill: #DEB372;
      @media (max-width: 1023px) {
        width: 21px;
        height: 21px;
      }
    }
  }
}

.search-popup {
  &.upd-popup-holder {
    .upd-popup {
      padding: 60px;
      border-radius: 20px;
      max-width: 700px;
      .close-popup {
        font-size: 0;
      }
      .info-block {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;
        @media screen and (min-width: 768px) {
          padding-right: 0;
          margin-bottom: 40px;
        }
      }

      .icon {
        width: 50px;
        height: 50px;
        flex-shrink: 0;
        margin-right: 23px;
        @media screen and (min-width: 768px) {
          width: 72px;
          height: 72px;
          margin-right: 30px;
        }

        svg {
          width: 100%;
          height: 100%;
        }
      }

      h4 {
        font-size: 33px;
        line-height: 1.16;
        font-weight: 500;
        letter-spacing: 0px;
        color: #B88B48;
        margin-bottom: 0;
        @media screen and (min-width: 768px) {
          font-size: 50px;
        }
      }
      .form-row {
        margin: 20px 0 0;
        text-align: center;
      }
      .btn {
        border-radius: 48px;
        padding: 13px 27px;
        font-weight: 700;
        font-size: 15px;
        margin: 0 6px;
        text-transform: uppercase;
        @media screen and (min-width: 768px) {
          font-size: 18px;
          margin: 0 12px;
          padding: 10px 24px;
        }

        i {
          font-size: 16px;
          margin-right: 10px;
          @media screen and (min-width: 768px) {
            margin-right: 20px;
          }
        }

        &.btn-submit {
          @media screen and (min-width: 768px) {
            padding-left: 40px;
            padding-right: 40px;
          }

          i {
            margin-right: 0;
            margin-left: 10px;
          }
        }
      }
    }
  }
}