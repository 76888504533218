@font-face {
  font-family: 'Recoleta Alt';
  src:
    url(../fonts/RecoletaAlt-Black.woff2) format('woff2'),
    url(../fonts/RecoletaAlt-Black.ttf) format('truetype');
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: 'Recoleta Alt';
  src:
    url(../fonts/RecoletaAlt-Bold.woff2) format('woff2'),
    url(../fonts/RecoletaAlt-Bold.ttf) format('truetype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Recoleta Alt';
  src:
    url(../fonts/RecoletaAlt-Light.woff2) format('woff2'),
    url(../fonts/RecoletaAlt-Light.ttf) format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Recoleta Alt';
  src:
    url(../fonts/RecoletaAlt-Medium.woff2) format('woff2'),
    url(../fonts/RecoletaAlt-Medium.ttf) format('truetype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Recoleta Alt';
  src:
    url(../fonts/RecoletaAlt-Regular.woff2) format('woff2'),
    url(../fonts/RecoletaAlt-Regular.ttf) format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Recoleta Alt';
  src:
    url(../fonts/RecoletaAlt-SemiBold.woff2) format('woff2'),
    url(../fonts/RecoletaAlt-SemiBold.ttf) format('truetype');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Recoleta Alt';
  src:
    url(../fonts/RecoletaAlt-Thin.woff2) format('woff2'),
    url(../fonts/RecoletaAlt-Thin.ttf) format('truetype');
  font-weight: 100;
  font-style: normal;
}
body {
  font-family: Roboto, Arial, Helvetica, sans-serif;
  background-color: #fff;
}
@media (max-width: 1023px) {
  .d-lg {
    display: none;
  }
}
.d-sm {
  display: none;
}
@media (max-width: 1023px) {
  .d-sm {
    display: block;
  }
}
.container {
  max-width: 1166px;
}
@media (max-width: 767px) {
  .container {
    padding-left: 25px;
    padding-right: 25px;
  }
}
@media (max-width: 374px) {
  .container {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.btn,
.btn[type='button'],
.btn[type='submit'] {
  font-family: Roboto, Arial, Helvetica, sans-serif;
}
.btn.btn-danger {
  background-color: #e43030;
  border: 1px solid #e43030;
  color: #fff;
  font-size: 14px;
  line-height: 1.36;
  font-weight: 900;
  border-radius: 3em;
  padding: 11px 15px;
  font-family: 'Recoleta Alt', Arial, Helvetica, sans-serif;
  min-width: 150px;
}
.btn.btn-danger:hover {
  color: #e43030;
  background-color: #fff;
}
.btn.btn-outline-secondary {
  border: 1px solid #898989;
  font-size: 13px;
  font-weight: 700;
  color: #191919;
  padding: 7px 18px;
  text-transform: uppercase;
  min-width: 0;
}
.btn.btn-outline-secondary:hover {
  background-color: #deb372;
  border-color: #deb372;
  color: #191919;
}
.hide-input {
  top: auto;
}
.red-text {
  color: #e43030;
}
#header.header {
  padding: 40px 0 13px;
}
@media (max-width: 1023px) {
  #header.header {
    padding: 10px 0 0;
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100%;
  }
}
#header_placeholder {
  display: none;
}
@media (max-width: 1023px) {
  #header_placeholder {
    display: block;
    min-height: 115px;
  }
}
@media (max-width: 767px) {
  #header_placeholder {
    min-height: 79px;
  }
}
.header {
  color: #090509;
  border: solid #deb372;
  border-width: 0 0 4px;
}
.header .container {
  max-width: 1180px;
  padding-left: 15px;
  padding-right: 15px;
}
.header .logo {
  max-width: 133px;
  margin-right: 12px;
}
@media (max-width: 1199px) {
  .header .logo {
    margin: 0;
    max-width: 100px;
  }
}
@media (max-width: 767px) {
  .header .logo {
    max-width: 64px;
  }
}
.header .header-holder {
  position: relative;
  z-index: 25;
}
@media (max-width: 1023px) {
  .header .header-holder {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 0 10px;
  }
}
@media (max-width: 1023px) {
  .header .nav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-left: 33px;
  }
}
@media (max-width: 1023px) {
  .header .nav .menu-opener {
    position: relative;
    z-index: 1000;
    top: auto;
    right: auto;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    width: 28px;
    height: 18px;
  }
}
.header .nav .menu-opener:after {
  text-outline: 17px;
}
@media (max-width: 1023px) {
  .header .nav .menu {
    position: fixed;
    top: 0;
    height: 100vh;
    overflow: auto;
    background-color: #000;
    color: #fff;
    display: block;
    text-align: left;
  }
}
@media (max-width: 1023px) {
  .header .nav .menu a {
    color: #fff;
  }
}
@media (max-width: 1023px) {
  .header .nav .menu .menu-open-close a {
    font-size: 18px;
  }
}
.header .header-info {
  margin-bottom: 0;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  padding-top: 8px;
}
@media (max-width: 1023px) {
  .header .header-info {
    padding-top: 0;
    margin-bottom: 5px;
  }
}
.header .header-info .text-right {
  text-align: right;
  padding-right: 15px;
}
.header .header-info .open-close li {
  width: auto;
}
.header .main-menu-holder {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border: solid #deb372;
  border-width: 0 0 1px;
  padding-bottom: 8px;
}
@media (max-width: 1023px) {
  .header .main-menu-holder {
    padding: 86px 50px 20px;
    display: none !important;
  }
}
@media (max-width: 767px) {
  .header .main-menu-holder {
    display: block;
    border: 0;
  }
}
.header .main-menu-holder .nav-holder {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 12px 0;
}
@media (max-width: 1023px) {
  .header .main-menu-holder .nav-holder {
    padding: 0;
    margin-bottom: 12px;
  }
}
@media (max-width: 1023px) {
  .header .main-menu-holder .nav-holder {
    display: block;
  }
}
.header .main-menu-holder .nav-holder .navbar {
  margin: 0 28px 0 0;
}
@media (max-width: 1199px) {
  .header .main-menu-holder .nav-holder .navbar {
    margin: 0 10px 0 0;
  }
}
@media (max-width: 1023px) {
  .header .main-menu-holder .nav-holder .navbar {
    margin: 0 0 20px;
  }
}
.header .main-menu-holder .nav-holder .navbar.sticky {
  position: static !important;
  height: auto;
  padding: 0;
  background-color: transparent;
  width: auto;
}
.header .main-menu-holder .sub-nav-holder {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-left: 15px;
}
@media (max-width: 1199px) {
  .header .main-menu-holder .sub-nav-holder {
    margin-left: 5px;
  }
}
@media (max-width: 1023px) {
  .header .main-menu-holder .sub-nav-holder {
    display: block;
    margin: 0;
    padding-top: 27px;
  }
}
.header .main-menu-holder .video-nav-opener {
  display: inline-block;
  vertical-align: middle;
  border: solid #dedede;
  border-width: 0 1px 0 0;
  padding-right: 18px;
  margin-right: 8px;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 13px;
  line-height: 1.15;
  color: #191919;
}
@media (max-width: 1023px) {
  .header .main-menu-holder .video-nav-opener {
    border: 0;
    padding: 0;
    margin-bottom: 15px;
    display: none;
  }
}
.header .main-menu-holder .video-nav-opener:hover {
  color: #deb372;
}
.header .main-menu-holder .video-nav-opener img {
  display: inline-block;
  vertical-align: middle;
  max-width: 28px;
  margin-right: 3px;
}
.header .main-menu-holder .sub-nav {
  margin: 0 -4px;
}
@media (max-width: 1023px) {
  .header .main-menu-holder .sub-nav {
    text-align: left;
    margin: 0;
    display: block;
  }
}
.header .main-menu-holder .sub-nav li {
  padding: 0 11px;
  margin: 0;
}
@media (max-width: 1199px) {
  .header .main-menu-holder .sub-nav li {
    padding: 0 5px;
  }
}
@media (max-width: 1023px) {
  .header .main-menu-holder .sub-nav li {
    padding: 0;
  }
}
.header .main-menu-holder .sub-nav li:after {
  content: none;
}
.header .main-menu-holder .sub-nav a {
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 13px;
  line-height: 1.15;
  color: #191919;
}
@media (max-width: 1023px) {
  .header .main-menu-holder .sub-nav a {
    display: block;
    position: relative;
    padding: 16px 30px 15px 0;
    font-size: 20px;
    border: solid #fff;
    border-width: 0 0 1px;
    color: #fff;
  }
}
.header .main-menu-holder .sub-nav a:hover {
  color: #deb372;
}
.header .main-menu-holder .sub-nav img {
  display: inline-block;
  vertical-align: middle;
  max-width: 10px;
  margin-right: 1px;
}
@media (max-width: 1023px) {
  .header .main-menu-holder .sub-nav img {
    position: absolute;
    top: 50%;
    right: 0;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    max-width: 15px;
  }
}
.header .main-menu-holder .btn-danger {
  color: #fff;
  font-weight: 900;
  font-size: 14px;
  min-width: 208px;
}
@media (max-width: 1199px) {
  .header .main-menu-holder .btn-danger {
    min-width: 155px;
  }
}
@media (max-width: 1023px) {
  .header .main-menu-holder .btn-danger {
    min-width: 0;
    width: 100%;
    display: block;
    margin: 0 auto;
    max-width: 400px;
    font-size: 21px;
    padding: 14px 15px;
  }
}
.header .main-menu-holder .btn-danger:hover {
  color: #e43030;
}
.header .main-nav-holder {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0;
}
@media (max-width: 1023px) {
  .header .main-nav-holder {
    display: block;
  }
}
.header .main-nav-holder > li {
  margin: 0;
  padding: 0;
  position: relative;
}
.header .main-nav-holder > li:first-child > .opener {
  padding-left: 0;
}
.header .main-nav-holder > li:last-child > .opener {
  padding-right: 0;
}
.header .main-nav-holder > li.active > .opener {
  color: #deb372 !important;
}
.header .main-nav-holder > li.active > .opener:not(:only-child):after {
  -webkit-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  transform: rotate(-180deg);
}
@media (max-width: 1023px) {
  .header .main-nav-holder > li.active > .opener:not(:only-child):after {
    -webkit-transform: rotate(-180deg) translateY(50%);
    -ms-transform: rotate(-180deg) translateY(50%);
    transform: rotate(-180deg) translateY(50%);
  }
}
.header .main-nav-holder > li > .opener {
  display: inline-block;
  vertical-align: middle;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-size: 14px;
  line-height: 1.21;
  font-weight: 700;
  text-transform: uppercase;
  padding: 15px 13px 9px;
  position: relative;
  -webkit-transition:
    color 0.4s linear,
    background 0.4s linear,
    border-radius 0.4s linear;
  -o-transition:
    color 0.4s linear,
    background 0.4s linear,
    border-radius 0.4s linear;
  transition:
    color 0.4s linear,
    background 0.4s linear,
    border-radius 0.4s linear;
  background: 0 0;
}
@media (max-width: 1199px) {
  .header .main-nav-holder > li > .opener {
    padding: 13px 5px;
  }
}
@media (max-width: 1023px) {
  .header .main-nav-holder > li > .opener {
    padding: 12px 30px 11px 0;
    font-size: 20px;
    position: relative;
    border: solid #fff;
    border-width: 0 0 1px;
  }
}
.header .main-nav-holder > li > .opener:not(:only-child):after {
  content: '';
  display: inline-block;
  vertical-align: middle;
  margin-left: 8px;
  -webkit-transition:
    border 0.4s linear,
    -webkit-transform 0.4s linear;
  transition:
    border 0.4s linear,
    -webkit-transform 0.4s linear;
  -o-transition:
    transform 0.4s linear,
    border 0.4s linear;
  transition:
    transform 0.4s linear,
    border 0.4s linear;
  transition:
    transform 0.4s linear,
    border 0.4s linear,
    -webkit-transform 0.4s linear;
  position: static;
  opacity: 1;
  visibility: visible;
  content: '\f107';
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  width: auto;
  height: auto;
  border: 0;
}
@media (max-width: 1023px) {
  .header .main-nav-holder > li > .opener:not(:only-child):after {
    border-color: #000 transparent transparent transparent;
    position: absolute;
    top: 50%;
    right: 0;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
}
@media (max-width: 1023px) {
  .header .main-nav-holder > li > .opener {
    display: block;
  }
}
.header .main-nav-holder > li > .opener:before {
  content: none;
}
.header .main-nav-holder > li > .opener:after {
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
  line-height: 1;
  margin-top: -2px;
}
.header .main-nav-holder > li > .menu-slide {
  top: calc(100% - 2px);
  left: -10px;
  min-height: 0;
  min-width: 190px;
}
@media (max-width: 1023px) {
  .header .main-nav-holder > li > .menu-slide {
    top: calc(100% - 1px);
    width: 100%;
  }
}
.header .main-nav-holder > li > .menu-slide:before {
  content: '';
  position: absolute;
  bottom: 100%;
  left: 0;
  right: 0;
  height: 9px;
}
.header .main-nav-holder a {
  font-size: 13px;
}
.menu-active .header .menu-opener:after,
.menu-active .header .menu-opener:before {
  background-color: #fff;
}
@media (max-width: 1023px) {
  .menu-active .header .main-menu-holder {
    display: block !important;
  }
}
.mob-sub-nav {
  display: none;
}
@media (max-width: 1023px) {
  .mob-sub-nav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    font-weight: 700;
    font-size: 15px;
    margin-right: -15px;
  }
}
.mob-sub-nav li {
  margin-right: 15px;
}
.mob-sub-nav li:last-child a {
  color: #898989;
}
.mob-sub-nav li:last-child a:hover {
  color: #deb372;
}
.mob-sub-nav a {
  color: #191919;
}
.mob-sub-nav a:hover {
  color: #deb372;
}
.mob-sub-nav a i {
  display: inline-block;
  vertical-align: middle;
  margin-left: 5px;
  font-size: 1.25em;
}
#header.inner-page .nav .menu-bottom > li {
  padding: 25px 40px 5px;
}
#header.inner-page .nav .menu-bottom a {
  font-size: 15px;
}
.header-info .open-close .slide a:hover,
.nav .menu-bottom > li.active > a {
  color: #e2b376;
}
.nav .menu-bottom-opener {
  display: none;
}
@media (max-width: 1023px) {
  .nav .menu-bottom-opener {
    display: block;
    font-size: 0;
    width: 22px;
    height: 15px;
    position: absolute;
    z-index: 200;
    top: 41px;
    right: 15px;
  }
  .nav .menu-bottom-opener span,
  .nav .menu-bottom-opener:after,
  .nav .menu-bottom-opener:before {
    -webkit-transition: all 0.1s linear;
    -o-transition: all 0.1s linear;
    transition: all 0.1s linear;
    position: absolute;
    left: 0;
    width: 100%;
    height: 2px;
    border-radius: 3em;
    background-color: #000;
  }
}
@media (max-width: 479px) {
  .nav .menu-bottom-opener {
    top: 32px;
    right: 8px;
  }
}
.nav .menu-bottom {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
@media (max-width: 1023px) {
  .nav .menu-bottom-opener:after,
  .nav .menu-bottom-opener:before {
    content: '';
    top: 0;
  }
  .nav .menu-bottom-opener:after {
    top: 14px;
  }
  .nav .menu-bottom-opener span {
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  .nav .menu-bottom {
    -webkit-transition:
      opacity 0.1s linear,
      visibility 0.1s linear;
    -o-transition:
      opacity 0.1s linear,
      visibility 0.1s linear;
    transition:
      opacity 0.1s linear,
      visibility 0.1s linear;
    opacity: 0;
    visibility: hidden;
    position: absolute;
    z-index: 100;
    top: 86px;
    left: 0;
    width: 100%;
    background-color: #f2f2f2;
    text-align: center;
    margin: 0;
    display: block;
    padding: 11px;
  }
  .nav .menu-bottom > li.active-page:before {
    content: none;
  }
}
@media (max-width: 479px) {
  .nav .menu-bottom {
    top: 68px;
  }
}
.nav .menu-bottom > li {
  padding: 26px 38px 5px;
  position: relative;
  z-index: 20;
}
@media (max-width: 1199px) {
  .nav .menu-bottom > li {
    padding: 8px 19px;
    margin: 0 0 8px;
  }
}
.nav .menu-bottom > li.active-page:before {
  opacity: 1;
  visibility: visible;
}
.nav .menu-bottom .inner-nav:before,
.nav .menu-bottom > li:before,
.nav .menu-bottom > li > .slide:before {
  content: '';
}
.nav .menu-bottom > li:before {
  position: absolute;
  z-index: 1;
  top: calc(100% + 3px);
  left: 46.5%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 56%;
  height: 2px;
  background-color: #e2b376;
  -webkit-transition:
    opacity 0.1s linear,
    visibility 0.1s linear;
  -o-transition:
    opacity 0.1s linear,
    visibility 0.1s linear;
  transition:
    opacity 0.1s linear,
    visibility 0.1s linear;
  opacity: 0;
  visibility: hidden;
}
.nav .menu-bottom > li > a {
  text-transform: uppercase;
  letter-spacing: -0.5px;
  position: relative;
  display: block;
  padding: 0 0 0 5px;
}
@media (max-width: 1023px) {
  .nav .menu-bottom > li > a {
    display: inline-block;
    vertical-align: middle;
    padding: 0 0 0 15px;
  }
}
.nav .menu-bottom > li > a .item-icon {
  position: absolute;
  top: -3px;
  right: calc(100% - 2px);
  -webkit-transform: scale(0.53);
  -ms-transform: scale(0.53);
  transform: scale(0.53);
  -webkit-transform-origin: 100% 0;
  -ms-transform-origin: 100% 0;
  transform-origin: 100% 0;
}
.nav .menu-bottom > li > .slide {
  position: absolute;
  z-index: 1001;
  top: 100%;
  left: 0;
  background-color: #fff;
  display: none;
  overflow: visible !important;
}
@media (max-width: 1023px) {
  .nav .menu-bottom > li > .slide {
    position: relative;
  }
}
.nav .menu-bottom > li > .slide:before {
  background-color: #f2f2f2;
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 200vw;
}
.nav .menu-bottom a {
  color: #000;
  font-weight: 500;
  font-size: 16px;
}
.nav .menu-bottom a:hover {
  color: #e2b376;
}
.nav .menu-bottom .inner-nav {
  padding: 47px 19px;
  -webkit-columns: 2;
  columns: 3;
  -webkit-column-gap: 20px;
  column-gap: 20px;
  position: relative;
}
.nav .menu-bottom .inner-nav:before {
  position: absolute;
  top: 26px;
  left: 19px;
  width: 60%;
  height: 1px;
  background-color: #e2b376;
}
.nav .menu-bottom .inner-nav li {
  white-space: nowrap;
  line-height: 25px;
  min-width: 210px;
}
.nav .menu-bottom .inner-nav a {
  font-weight: 400;
}
@media (max-width: 1023px) {
  .nav .menu-bottom .inner-nav {
    -webkit-columns: 1;
    columns: 1;
  }
  .nav .menu-bottom .inner-nav:before {
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
  }
  .nav .menu-bottom .inner-nav li {
    min-width: 0;
  }
  .menu-bottom-active .menu-bottom-opener:after,
  .menu-bottom-active .menu-bottom-opener:before {
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%) rotate(45deg);
    -ms-transform: translate(-50%, -50%) rotate(45deg);
    transform: translate(-50%, -50%) rotate(45deg);
  }
  .menu-bottom-active .menu-bottom-opener:after {
    -webkit-transform: translate(-50%, -50%) rotate(-45deg);
    -ms-transform: translate(-50%, -50%) rotate(-45deg);
    transform: translate(-50%, -50%) rotate(-45deg);
  }
  .menu-bottom-active .menu-bottom-opener span {
    opacity: 0;
  }
}
.nav .menu-bottom > li.active-page > a {
  color: #deb372;
}
@media (max-width: 1023px) {
  .header-bottom {
    display: none;
  }
}
.header-bottom .nav .menu-bottom {
  margin: 0 -15px;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.header-bottom .nav .menu-bottom > li {
  padding: 0;
}
.header-bottom .nav .menu-bottom > li:before {
  content: none;
  background: 0 0;
}
.header-bottom .nav .menu-bottom > li > a {
  font-family: 'Recoleta Alt', Arial, Helvetica, sans-serif;
  font-weight: 900;
  font-size: 16px;
  line-height: 1;
  letter-spacing: 0;
  display: block;
  padding: 10px 15px;
}
.header-bottom .nav .menu-bottom > li > a .item-icon {
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
  max-width: 22px;
  position: static;
  display: inline-block;
  vertical-align: middle;
  margin-right: 8px;
}
.header-bottom .nav .menu-bottom > li > a:not(:only-child):after {
  content: '';
  display: inline-block;
  vertical-align: middle;
  margin-left: 8px;
  -webkit-transition:
    border 0.4s linear,
    -webkit-transform 0.4s linear;
  transition:
    border 0.4s linear,
    -webkit-transform 0.4s linear;
  -o-transition:
    transform 0.4s linear,
    border 0.4s linear;
  transition:
    transform 0.4s linear,
    border 0.4s linear;
  transition:
    transform 0.4s linear,
    border 0.4s linear,
    -webkit-transform 0.4s linear;
  position: static;
  opacity: 1;
  visibility: visible;
  content: '\f107';
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  width: auto;
  height: auto;
  border: 0;
}
@media (max-width: 1023px) {
  .header-bottom .nav .menu-bottom > li > a:not(:only-child):after {
    border-color: #000 transparent transparent transparent;
  }
}
.header-bottom .nav .menu-bottom > li > .slide {
  background-color: #f5f5f5;
  border-radius: 10px;
  -webkit-box-shadow:
    2px 4px 9px 0 rgba(0, 0, 0, 0.1),
    inset 0 0 1px 0 rgba(25, 25, 25, 0.2);
  box-shadow:
    2px 4px 9px 0 rgba(0, 0, 0, 0.1),
    inset 0 0 1px 0 rgba(25, 25, 25, 0.2);
  border: 0;
  padding: 9px 0;
  min-width: 247px;
}
.header-bottom .nav .menu-bottom > li > .slide:before {
  content: none;
}
.header-bottom .nav .menu-bottom .inner-nav {
  -webkit-columns: unset;
  columns: unset;
  padding: 0;
}
.header-bottom .nav .menu-bottom .inner-nav:before {
  content: none;
}
.header-bottom .nav .menu-bottom .inner-nav a {
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-size: 13px;
  line-height: 1.38;
  text-transform: uppercase;
  color: #191919;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  white-space: nowrap;
  position: relative;
  z-index: 5;
  display: block;
  padding: 14px 20px;
  -webkit-transition:
    background 0.4s linear,
    color 0.4s linear;
  -o-transition:
    background 0.4s linear,
    color 0.4s linear;
  transition:
    background 0.4s linear,
    color 0.4s linear;
}
.header-bottom .nav .menu-bottom .inner-nav a:only-child:after {
  content: none;
}
@media (max-width: 1199px) {
  .header-bottom .nav .menu-bottom .inner-nav a {
    white-space: normal;
  }
}
.header-bottom .nav .menu-bottom .inner-nav a:hover {
  color: #191919;
  background-color: #eee;
}
.header-bottom .nav .menu-bottom .inner-nav a:hover:after {
  opacity: 1;
  visibility: visible;
}
.header-bottom .nav .menu-bottom .inner-nav a:after {
  content: '\f105';
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  position: absolute;
  top: 50%;
  right: 18px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  opacity: 0;
  visibility: hidden;
  -webkit-transition:
    opacity 0.4s linear,
    visibility 0.4s linear;
  -o-transition:
    opacity 0.4s linear,
    visibility 0.4s linear;
  transition:
    opacity 0.4s linear,
    visibility 0.4s linear;
}
.header-bottom .sticky-block {
  background-color: #fff;
}
.sticky-block.block-scrolled {
  position: fixed;
  z-index: 500;
  top: 0;
  left: 0;
  width: 100%;
  border: solid #deb372;
  border-width: 0 0 4px;
}
.faq-link {
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-weight: 700;
  font-size: 10px;
  line-height: 1.3;
  color: #898989;
  text-transform: uppercase;
}
.faq-link:hover {
  color: #e43030;
}
.menu-open-close > li {
  margin-bottom: 9px;
  margin-bottom: 0;
  position: relative;
}
@media (max-width: 1023px) {
  .menu-open-close > li {
    position: relative;
  }
}
.menu-open-close > li.active > a,
.menu-open-close > li:hover > a {
  color: #191919 !important;
  background-color: #eee;
}
@media (max-width: 1023px) {
  .menu-open-close > li.active > a,
  .menu-open-close > li:hover > a {
    color: #deb372 !important;
    background: 0 0;
  }
}
.menu-open-close > li.active > a:after,
.menu-open-close > li:hover > a:after {
  opacity: 1;
  visibility: visible;
}
.menu-open-close > li:last-child {
  margin-bottom: 0;
}
.menu-open-close > li:after,
.menu-open-close > li:before {
  position: absolute;
  z-index: 1;
  width: 150px;
  height: 20px;
}
.menu-open-close > li:before {
  right: 50%;
}
.menu-open-close > li:after {
  left: 50%;
}
.menu-open-close > li.active > a:after,
.menu-open-close > li.active > a:before {
  opacity: 1;
  visibility: visible;
}
.menu-open-close a {
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-size: 13px;
  line-height: 1.38;
  text-transform: uppercase;
  color: #191919;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  white-space: nowrap;
  position: relative;
  z-index: 5;
  display: block;
  padding: 14px 20px;
  -webkit-transition:
    background 0.4s linear,
    color 0.4s linear;
  -o-transition:
    background 0.4s linear,
    color 0.4s linear;
  transition:
    background 0.4s linear,
    color 0.4s linear;
}
@media (max-width: 1199px) {
  .menu-open-close a {
    white-space: normal;
  }
}
@media (max-width: 1023px) {
  .menu-open-close a {
    padding: 7px 0 6px;
    font-size: 18px;
  }
}
.menu-open-close a:only-child:after {
  content: none;
}
.menu-open-close a:after {
  content: '\f105';
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  position: absolute;
  top: 50%;
  right: 18px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  opacity: 0;
  visibility: hidden;
  -webkit-transition:
    opacity 0.4s linear,
    visibility 0.4s linear;
  -o-transition:
    opacity 0.4s linear,
    visibility 0.4s linear;
  transition:
    opacity 0.4s linear,
    visibility 0.4s linear;
}
@media (max-width: 1023px) {
  .menu-open-close a:after {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translateY(-50%) rotate(90deg);
    -ms-transform: translateY(-50%) rotate(90deg);
    transform: translateY(-50%) rotate(90deg);
    right: 0;
  }
}
.menu-open-close .mob-icon {
  display: none;
}
@media (max-width: 1023px) {
  .menu-open-close .mob-icon {
    display: inline-block;
    vertical-align: middle;
    max-width: 21px;
    margin-right: 5px;
  }
}
.menu-open-close .menu-slide {
  display: none;
  position: absolute;
  z-index: 10;
  top: 3px;
  left: calc(100% - 1px);
  background-color: #f5f5f5;
  border: 1px solid #191919;
  padding: 24px 20px 24px 27px;
  min-height: calc(100% + 2px);
  border-radius: 10px;
  -webkit-box-shadow:
    2px 4px 9px 0 rgba(0, 0, 0, 0.1),
    inset 0 0 1px 0 rgba(25, 25, 25, 0.2);
  box-shadow:
    2px 4px 9px 0 rgba(0, 0, 0, 0.1),
    inset 0 0 1px 0 rgba(25, 25, 25, 0.2);
  border: 0;
  padding: 9px 0;
  min-width: 247px;
}
@media (max-width: 1023px) {
  .menu-open-close .menu-slide {
    top: calc(100% + 5px);
    width: 100%;
    position: static;
    border: 0;
    background-color: transparent;
    border-radius: 0;
  }
}
@media (max-width: 1023px) {
  .menu-open-close .menu-slide .menu-slide {
    padding: 2px 0 11px 28px;
    border: solid #fff;
    border-width: 0 0 1px;
  }
}
@media (max-width: 1023px) {
  .menu-open-close .menu-slide .menu-slide a:after {
    opacity: 0;
    visibility: hidden;
  }
}
.menu-open-close .card-preview {
  display: block;
  position: static;
  min-width: 132px;
  max-width: 150px;
  margin: 0 auto;
  padding: 0 16px;
}
.menu-open-close .card-preview img {
  display: block;
  margin-bottom: 32px;
}
.menu-open-close .card-preview .card-preview-text {
  display: block;
  background-color: #deb372;
  padding: 13px 10px;
  text-align: center;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-weight: 700;
  font-size: 13px;
  line-height: 1.15;
  color: #191919;
  text-transform: uppercase;
  position: absolute;
  bottom: 0;
  margin: 0;
  left: 0;
  width: 100%;
}
.mobile-preview-block {
  display: none;
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #000;
  text-align: center;
}
@media (max-width: 767px) {
  .mobile-preview-block {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    -webkit-transition:
      opacity 0.4s linear,
      visibility 0.4s linear;
    -o-transition:
      opacity 0.4s linear,
      visibility 0.4s linear;
    transition:
      opacity 0.4s linear,
      visibility 0.4s linear;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}
@media (max-width: 767px) {
  .mobile-preview-block.loaded {
    opacity: 0;
    visibility: hidden;
  }
}
.mobile-preview-block .banner-content {
  padding: 10px 60px;
}
.mobile-preview-block .mobile-img {
  display: none;
}
@media (max-width: 767px) {
  .mobile-preview-block .mobile-img {
    display: block;
    max-width: 230px;
    margin: 0 auto 25px;
  }
}
.mobile-preview-block .banner-title {
  margin: 0;
  padding: 0;
  font-family: 'Recoleta Alt', Arial, Helvetica, sans-serif;
  font-weight: 900;
  font-size: 60px;
  line-height: 0.97;
  color: #deb372;
  text-transform: capitalize;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}
@media (max-width: 1023px) {
  .mobile-preview-block .banner-title {
    font-size: 40px;
  }
}
.mobile-preview-block .banner-title:only-child {
  text-align: center;
}
.mobile-preview-block .white-text {
  color: #fff;
}
.banner-section.active {
  margin-top: 0;
}
@media (max-width: 767px) {
  .banner-section.upd_section {
    display: none;
  }
}
.banner-section.upd_section .banner-holder {
  margin: 0;
  padding: 0;
}
.banner-section.upd_section .slick-slider {
  -webkit-user-select: auto;
  -moz-user-select: auto;
  -ms-user-select: auto;
  user-select: auto;
}
.banner-section.upd_section .banner {
  border-radius: 0;
  background-color: #000;
  text-align: center;
  padding: 45px 0 55px;
  position: relative;
}
@media (max-width: 767px) {
  .banner-section.upd_section .banner {
    padding: 40px 0;
    height: 100vh;
    overflow: auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}
.banner-section.upd_section .banner:after {
  min-height: 458px;
}
@media (max-width: 1023px) {
  .banner-section.upd_section .banner:after {
    min-height: 300px;
  }
}
.banner-section.upd_section .banner .container {
  text-align: left;
  max-width: 1016px;
  position: relative;
  z-index: 4;
}
@media (max-width: 479px) {
  .banner-section.upd_section .banner .container {
    padding: 0 15px;
  }
}
.banner-section.upd_section .banner .bg-video {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.banner-section.upd_section .banner .bg-video:before {
  content: '';
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.banner-section.upd_section .banner .bg-video iframe,
.banner-section.upd_section .banner .bg-video video {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.banner-section.upd_section .banner-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
@media (max-width: 1023px) {
  .banner-section.upd_section .banner-content {
    text-align: center;
  }
}
@media (max-width: 767px) {
  .banner-section.upd_section .banner-content {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    text-align: center;
    padding: 0 45px;
  }
}
.banner-section.upd_section .banner-content .mobile-img {
  display: none;
}
@media (max-width: 767px) {
  .banner-section.upd_section .banner-content .mobile-img {
    display: block;
    max-width: 230px;
    margin: 0 auto 25px;
  }
}
.banner-section.upd_section .banner-content .banner-title {
  margin: 0;
  padding: 0;
  font-family: 'Recoleta Alt', Arial, Helvetica, sans-serif;
  font-weight: 900;
  font-size: 60px;
  line-height: 0.97;
  color: #deb372;
  text-transform: capitalize;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}
@media (max-width: 1023px) {
  .banner-section.upd_section .banner-content .banner-title {
    font-size: 40px;
  }
}
.banner-section.upd_section .banner-content .banner-title:only-child {
  text-align: center;
}
.banner-section.upd_section .banner-content .white-text {
  color: #fff;
}
.banner-play-icon {
  display: block;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  margin-right: 50px;
  max-width: 117px;
}
@media (max-width: 1023px) {
  .banner-play-icon {
    max-width: 80px;
  }
}
@media (max-width: 767px) {
  .banner-play-icon {
    margin-right: 20px;
  }
}
@media (max-width: 479px) {
  .banner-play-icon {
    max-width: 50px;
  }
}
.underlined-text {
  text-decoration: underline;
}
.marked-text {
  color: #deb372;
}
.categories-list-section {
  padding: 91px 0 28px;
  background-color: #fff;
  color: #090509;
}
@media (max-width: 767px) {
  .categories-list-section {
    padding: 64px 0 20px;
  }
}
.section-heading {
  text-align: center;
  font-size: 25px;
  line-height: 1.15;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-weight: 500;
  margin-bottom: 101px;
}
@media (max-width: 767px) {
  .section-heading {
    padding: 0 10px;
    font-size: 14px;
    line-height: 1.45;
    margin-bottom: 31px;
  }
}
.section-heading .section-title {
  font-size: 60px;
  font-family: 'Recoleta Alt', Arial, Helvetica, sans-serif;
  font-weight: 900;
  line-height: 1.36;
  text-transform: capitalize;
  margin-bottom: 5px;
}
@media (max-width: 767px) {
  .section-heading .section-title {
    font-size: 40px;
    line-height: 0.95;
    margin-bottom: 15px;
  }
}
.section-heading .heading-note {
  max-width: 980px;
  margin: 0 auto;
}
@media (max-width: 767px) {
  .section-heading .heading-note {
    max-width: 220px;
  }
}
.section-heading .heading-note p {
  margin-bottom: 10px;
}
.section-heading .heading-note p:last-child {
  margin-bottom: 0;
}
.section-heading .heading-note a {
  color: inherit;
  text-decoration: underline;
}
.section-heading .heading-note a:hover {
  text-decoration: none;
}
.categories-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 -25px;
}
@media (max-width: 767px) {
  .categories-list {
    margin: 0 -10px;
  }
}
.categories-list .category-item {
  display: block;
  margin: 0 19px 39px;
  width: calc(25% - 38px);
  text-align: center;
  padding: 40px 15px 38px;
  border: 1px solid #deb372;
  border-radius: 10px;
  -webkit-transition:
    border 0.4s linear,
    -webkit-box-shadow 0.4s linear;
  transition:
    border 0.4s linear,
    -webkit-box-shadow 0.4s linear;
  -o-transition:
    box-shadow 0.4s linear,
    border 0.4s linear;
  transition:
    box-shadow 0.4s linear,
    border 0.4s linear;
  transition:
    box-shadow 0.4s linear,
    border 0.4s linear,
    -webkit-box-shadow 0.4s linear;
  color: inherit;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-size: 25px;
  line-height: 1.2;
  font-weight: 700;
  text-transform: uppercase;
}
@media (max-width: 1023px) {
  .categories-list .category-item {
    width: calc(33% - 38px);
    padding: 20px 15px;
  }
}
@media (max-width: 767px) {
  .categories-list .category-item {
    width: calc(50% - 20px);
    padding: 15px 10px 25px;
    border-radius: 5px;
    margin: 0 10px 24px;
    font-size: 15px;
  }
}
.categories-list .category-item:hover {
  -webkit-box-shadow: 0 0 32px 0 rgba(72, 44, 0, 0.18);
  box-shadow: 0 0 32px 0 rgba(72, 44, 0, 0.18);
}
.categories-list .category-item .img-holder {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 110px;
  margin-bottom: 36px;
}
@media (max-width: 767px) {
  .categories-list .category-item .img-holder {
    height: 80px;
    margin-bottom: 20px;
  }
}
.categories-list .category-item .img-holder img {
  max-width: 124px;
  max-height: 100%;
  width: auto;
  height: auto;
  display: block;
}
.upd_card-slider-section {
  background-color: #fff;
  padding: 20px 0 60px;
}
@media (max-width: 767px) {
  .upd_card-slider-section .section-heading .section-title {
    margin-bottom: 21px;
  }
}
@media (max-width: 767px) {
  .upd_card-slider-section .section-heading .heading-note {
    max-width: 200px;
  }
}
.upd_card-slider {
  position: relative;
  padding: 0 20px;
  max-width: 1000px;
  margin: 0 auto;
}
@media (max-width: 767px) {
  .upd_card-slider {
    padding: 0 30px;
    padding: 0;
  }
}
.upd_card-slider .slick-track {
  padding-top: 24%;
  padding-bottom: 24%;
}
@media (max-width: 767px) {
  .upd_card-slider .slick-track {
    padding-top: 21%;
    padding-bottom: 21%;
  }
}
.upd_card-slider .upd_card-slide {
  -webkit-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  -webkit-transform: scale(0.57);
  -ms-transform: scale(0.57);
  transform: scale(0.57);
  -webkit-transition:
    margin 0.4s linear,
    -webkit-transform 0.4s linear,
    -webkit-transform-origin 0.4s linear;
  transition:
    margin 0.4s linear,
    -webkit-transform 0.4s linear,
    -webkit-transform-origin 0.4s linear;
  -o-transition:
    transform 0.4s linear,
    transform-origin 0.4s linear,
    margin 0.4s linear;
  transition:
    transform 0.4s linear,
    transform-origin 0.4s linear,
    margin 0.4s linear;
  transition:
    transform 0.4s linear,
    transform-origin 0.4s linear,
    margin 0.4s linear,
    -webkit-transform 0.4s linear,
    -webkit-transform-origin 0.4s linear,
    -ms-transform-origin 0.4s linear;
  border: 0;
  outline: 0;
  position: relative;
  text-align: center;
}
.upd_card-slider .upd_card-slide .card-holder {
  display: inline-block;
  vertical-align: middle;
}
.upd_card-slider .upd_card-slide .card-holder > img {
  max-height: 250px;
  max-width: 100%;
  width: auto;
  height: auto;
  margin: 0 auto;
}
.upd_card-slider .upd_card-slide.slick-active {
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
  -webkit-transform: scale(1.9) translateX(24%);
  -ms-transform: scale(1.9) translateX(24%);
  transform: scale(1.9) translateX(24%);
  -webkit-transform: scale(1.9);
  -ms-transform: scale(1.9);
  transform: scale(1.9);
  -webkit-transform-origin: 0 50%;
  -ms-transform-origin: 0 50%;
  transform-origin: 0 50%;
}
.upd_card-slider .upd_card-slide.slick-active .overlay-block .btn {
  -webkit-transform: scale(0.53);
  -ms-transform: scale(0.53);
  transform: scale(0.53);
}
.upd_card-slider .upd_card-slide.slick-active + .slick-active {
  -webkit-transform: scale(2.1) translateX(9%);
  -ms-transform: scale(2.1) translateX(9%);
  transform: scale(2.1) translateX(9%);
  -webkit-transform: scale(2.1);
  -ms-transform: scale(2.1);
  transform: scale(2.1);
  -webkit-transform-origin: 33% 50%;
  -ms-transform-origin: 33% 50%;
  transform-origin: 33% 50%;
}
.upd_card-slider .upd_card-slide.slick-active + .slick-active .overlay-block .btn {
  -webkit-transform: scale(0.48);
  -ms-transform: scale(0.48);
  transform: scale(0.48);
}
.upd_card-slider .upd_card-slide.slick-active.slick-current {
  z-index: 3;
  -webkit-transform: scale(2.55);
  -ms-transform: scale(2.55);
  transform: scale(2.55);
  -webkit-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
}
.upd_card-slider .upd_card-slide.slick-active.slick-current .card-holder:hover .overlay-block {
  opacity: 1;
  visibility: visible;
}
.upd_card-slider .upd_card-slide.slick-active.slick-current .overlay-block .btn {
  -webkit-transform: scale(0.39);
  -ms-transform: scale(0.39);
  transform: scale(0.39);
}
.upd_card-slider .upd_card-slide.slick-active.slick-current + .slick-active {
  z-index: 2;
  -webkit-transform: scale(2.1) translateX(-9%);
  -ms-transform: scale(2.1) translateX(-9%);
  transform: scale(2.1) translateX(-9%);
  -webkit-transform: scale(2.1);
  -ms-transform: scale(2.1);
  transform: scale(2.1);
  -webkit-transform-origin: 67% 50%;
  -ms-transform-origin: 67% 50%;
  transform-origin: 67% 50%;
}
.upd_card-slider .upd_card-slide.slick-active.slick-current + .slick-active .overlay-block .btn {
  -webkit-transform: scale(0.48);
  -ms-transform: scale(0.48);
  transform: scale(0.48);
}
.upd_card-slider .upd_card-slide.slick-active.slick-current + .slick-active + .slick-active {
  z-index: 1;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
  -webkit-transform: scale(1.9) translateX(-24%);
  -ms-transform: scale(1.9) translateX(-24%);
  transform: scale(1.9) translateX(-24%);
  -webkit-transform: scale(1.9);
  -ms-transform: scale(1.9);
  transform: scale(1.9);
  -webkit-transform-origin: 100% 50%;
  -ms-transform-origin: 100% 50%;
  transform-origin: 100% 50%;
}
.upd_card-slider
  .upd_card-slide.slick-active.slick-current
  + .slick-active
  + .slick-active
  .overlay-block
  .btn {
  -webkit-transform: scale(0.53);
  -ms-transform: scale(0.53);
  transform: scale(0.53);
}
.upd_card-slider .upd_card-slide.slick-current {
  position: relative;
  z-index: 2;
}
.upd_card-slider .slick-arrow {
  font-size: 0;
  outline: 0;
  border: 0;
  background-color: transparent;
  display: block;
  width: 44px;
  height: 44px;
  background-image: url(../images/icon-06.png);
  background-size: contain;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  position: absolute;
  z-index: 10;
  top: 48%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
@media (max-width: 767px) {
  .upd_card-slider .slick-arrow {
    width: 20px;
    height: 20px;
  }
}
.upd_card-slider .slick-arrow.slick-prev {
  left: 0;
  left: -7%;
}
.upd_card-slider .slick-arrow.slick-next {
  background-image: url(../images/icon-07.png);
  right: 0;
  right: -7%;
}
.upd_card-slider .card-holder {
  display: block;
  position: relative;
  border-radius: 5px;
  overflow: hidden;
}
.upd_card-slider .card-holder img {
  display: block;
  margin: 0;
}
.upd_card-slider .card-holder .overlay-block {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.8);
  -webkit-transition:
    opacity 0.4s linear,
    visibility 0.4s linear;
  -o-transition:
    opacity 0.4s linear,
    visibility 0.4s linear;
  transition:
    opacity 0.4s linear,
    visibility 0.4s linear;
  opacity: 0;
  visibility: hidden;
}
.upd_card-slider .card-holder .overlay-block .btn {
  display: inline-block;
  vertical-align: middle;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-size: 18px;
  line-height: 1.15;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  min-width: 176px;
  padding: 12px 15px;
  border: 1px solid #fff;
  border-radius: 3em;
  -webkit-transition:
    color 0.4s linear,
    background 0.4s linear,
    -webkit-transform 0.4s linear;
  transition:
    color 0.4s linear,
    background 0.4s linear,
    -webkit-transform 0.4s linear;
  -o-transition:
    color 0.4s linear,
    background 0.4s linear,
    transform 0.4s linear;
  transition:
    color 0.4s linear,
    background 0.4s linear,
    transform 0.4s linear;
  transition:
    color 0.4s linear,
    background 0.4s linear,
    transform 0.4s linear,
    -webkit-transform 0.4s linear;
}
@media (max-width: 767px) {
  .upd_card-slider .card-holder .overlay-block .btn {
    font-size: 0;
    min-width: 0;
    border-radius: 50%;
    padding: 10px;
  }
}
.upd_card-slider .card-holder .overlay-block .btn img {
  display: inline-block;
  vertical-align: middle;
  margin-left: 5px;
  max-width: 20px;
}
@media (max-width: 767px) {
  .upd_card-slider .card-holder .overlay-block .btn img {
    margin-left: 0;
    max-width: 25px;
  }
}
.editor-preview-section {
  background-color: #fff;
  background-position: top;
  background-size: 100% auto;
  background-repeat: no-repeat;
  padding: 139px 0 5px;
}
@media (max-width: 767px) {
  .editor-preview-section {
    padding: 77px 0 20px;
    background-size: auto 50%;
  }
}
.editor-preview-section .section-heading {
  margin-bottom: 16px;
  position: relative;
  z-index: 2;
}
@media (max-width: 1023px) {
  .editor-preview-section .section-heading {
    margin-bottom: 50px;
  }
}
@media (max-width: 479px) {
  .editor-preview-section .section-heading {
    margin-bottom: 16px;
  }
}
.editor-preview-section .section-heading .section-title {
  text-transform: none;
}
@media (max-width: 767px) {
  .editor-preview-section .section-heading .section-title {
    margin-bottom: 24px;
  }
}
@media (max-width: 767px) {
  .editor-preview-section .section-heading .heading-note {
    max-width: 280px;
  }
}
.custom-delimiter {
  display: block;
  width: 100%;
  height: 24px;
  margin: 20px 0;
  background-image: url(../images/img-11.png);
  background-size: contain;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}
@media (max-width: 767px) {
  .custom-delimiter {
    width: 85%;
    margin: 20px auto;
  }
}
.editor-preview-block {
  position: relative;
  padding: 20px 0 0;
  margin: 0 -70px 32px;
}
@media (max-width: 1199px) {
  .editor-preview-block {
    margin: 0 0 58px;
  }
}
.editor-preview-block iframe,
.editor-preview-block img {
  display: block;
}
@media (max-width: 1023px) {
  .editor-preview-block .preview-img {
    border-radius: 10px;
    -webkit-box-shadow: 0 0 46px 0 rgba(0, 0, 0, 0.16);
    box-shadow: 0 0 46px 0 rgba(0, 0, 0, 0.16);
  }
}
.editor-preview-block .decor {
  position: absolute;
  max-width: 28%;
  -webkit-animation: floating ease-in-out 4s infinite;
  animation: floating ease-in-out 4s infinite;
}
@media (max-width: 479px) {
  .editor-preview-block .decor {
    max-width: 65%;
  }
}
.editor-preview-block .decor.decor-top {
  top: -6%;
  left: 57%;
  width: 24%;
}
@media (max-width: 479px) {
  .editor-preview-block .decor.decor-top {
    top: -10.5%;
    left: 38%;
    width: 65%;
  }
}
.editor-preview-block .decor.decor-left {
  top: 50.5%;
  left: -8%;
  width: 28%;
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}
@media (max-width: 479px) {
  .editor-preview-block .decor.decor-left {
    top: 65.5%;
    left: -25%;
    width: 51%;
  }
}
.editor-preview-block .decor.decor-right {
  top: 29%;
  left: 89%;
  width: 11%;
  -webkit-animation-delay: 3s;
  animation-delay: 3s;
}
@media (max-width: 479px) {
  .editor-preview-block .decor.decor-right {
    top: 44%;
    left: 83%;
    width: 33%;
  }
}
@media (max-width: 1023px) {
  .editor-preview-block .d-lg {
    display: none;
  }
}
.editor-preview-block .d-sm {
  display: none;
}
@media (max-width: 1023px) {
  .editor-preview-block .d-sm {
    display: block;
  }
}
.user-interface-section {
  padding: 70px 0 20px;
  background-color: #fff;
}
@media (max-width: 767px) {
  .user-interface-section {
    padding: 19px 0;
  }
}
.cta-custom-holder .cta-custom-block {
  background-color: #000;
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 246px 0 196px;
  border: solid #e43030;
  border-width: 15px 0 0;
}
@media (max-width: 1199px) {
  .cta-custom-holder .cta-custom-block {
    padding: 150px 0;
  }
}
@media (max-width: 1023px) {
  .cta-custom-holder .cta-custom-block {
    padding: 100px 0;
  }
}
@media (max-width: 767px) {
  .cta-custom-holder .cta-custom-block {
    padding: 75px 0;
    border-width: 8px 0 0;
  }
}
.cta-custom-holder .container {
  max-width: 1167px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
@media (max-width: 1023px) {
  .cta-custom-holder .container {
    display: block;
  }
}
.cta-custom-holder .container .text-block {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}
.cta-custom-holder .text-block {
  width: 50%;
}
@media (max-width: 1023px) {
  .cta-custom-holder .text-block {
    margin-bottom: 40px;
    text-align: center;
    width: 100%;
  }
}
@media (min-width: 1600px) {
  .cta-custom-holder .text-block {
    margin-bottom: 55px;
  }
}
.cta-custom-holder .phone-block {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  position: relative;
  padding: 0 11px;
  width: 50%;
}
@media (max-width: 1023px) {
  .cta-custom-holder .phone-block {
    padding: 0;
    margin: 0;
    width: 100%;
    text-align: center;
  }
}
@media (max-width: 479px) {
  .cta-custom-holder .phone-block {
    padding: 47px 0 0;
  }
}
.cta-custom-holder .phone-block .img-lg {
  display: block;
  margin: 0 auto;
}
@media (min-width: 1600px) {
  .cta-custom-holder .phone-block .img-lg {
    -webkit-transform-origin: 50% 50%;
    -ms-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-transform: scale(1.4) translateX(14%);
    -ms-transform: scale(1.4) translateX(14%);
    transform: scale(1.4) translateX(14%);
    margin-top: 16px;
  }
}
@media (max-width: 1023px) {
  .cta-custom-holder .phone-block .img-lg {
    width: 400px;
    margin-bottom: 30px;
  }
}
@media (max-width: 479px) {
  .cta-custom-holder .phone-block .img-lg {
    -webkit-transform: scale(1.35) translateX(7%);
    -ms-transform: scale(1.35) translateX(7%);
    transform: scale(1.35) translateX(7%);
    margin-bottom: 60px;
  }
}
.cta-custom-holder .phone-block .banner-play-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.cta-custom-holder .phone-block .btn {
  display: none;
}
@media (max-width: 1023px) {
  .cta-custom-holder .phone-block .btn {
    display: inline-block;
  }
}
.cta-custom-holder .subtitle-img {
  max-width: 155px;
  margin-bottom: 32px;
}
@media (min-width: 1199px) {
  .cta-custom-holder .subtitle-img {
    margin-left: -20px;
    margin-bottom: 20px;
  }
}
@media (max-width: 767px) {
  .cta-custom-holder .subtitle-img {
    max-width: 124px;
  }
}
.cta-custom-holder h2 {
  font-family: 'Recoleta Alt', Arial, Helvetica, sans-serif;
  font-size: 60px;
  font-weight: 900;
  line-height: 0.96;
  color: #fff;
  margin-bottom: 54px;
}
@media (max-width: 1023px) {
  .cta-custom-holder h2 {
    margin-bottom: 20px;
  }
}
@media (max-width: 767px) {
  .cta-custom-holder h2 {
    font-size: 42px;
  }
}
.cta-custom-holder h2 .marked-text {
  color: #e43030;
}
.cta-custom-holder .btn-danger {
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-size: 17px;
  font-weight: 700;
  line-height: 1.15;
  padding: 12px 10px;
  vertical-align: middle;
  min-width: 192px;
  border: 1px solid #fff;
}
.cta-custom-holder .btn-danger:hover:after {
  border-color: transparent transparent transparent #e43030;
}
.cta-custom-holder .btn-danger:after {
  content: '';
  display: inline-block;
  vertical-align: middle;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 7.5px 0 7.5px 8px;
  border-color: transparent transparent transparent #fff;
  margin-left: 11px;
  margin-top: -5px;
  -webkit-transition: border 0.1s linear;
  -o-transition: border 0.1s linear;
  transition: border 0.1s linear;
}
.contact-details-section {
  background-color: transparent;
  padding: 79px 0 20px;
}
@media (max-width: 767px) {
  .contact-details-section {
    padding: 45px 0 20px;
  }
}
.contact-details-block .section-heading {
  font-size: 18px;
  margin-bottom: 43px;
}
@media (max-width: 767px) {
  .contact-details-block .section-heading {
    font-size: 15px;
    margin-bottom: 15px;
  }
}
.contact-details-block .section-title {
  font-size: 50px;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-weight: 500;
  text-transform: none;
  margin-bottom: 10px;
}
@media (max-width: 767px) {
  .contact-details-block .section-title {
    font-size: 43px;
    margin-bottom: 17px;
  }
}
@media (max-width: 767px) {
  .contact-details-block .heading-note {
    max-width: 270px;
  }
}
.contact-details-block .contact-details-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 20px;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-weight: 500;
  line-height: 1.2;
}
@media (max-width: 767px) {
  .contact-details-block .contact-details-list {
    font-size: 15px;
    display: block;
  }
}
.contact-details-block .contact-details-list li {
  text-align: center;
  border: solid #deb372;
  border-width: 0 1px 0 0;
  padding: 12px 33px;
  margin-bottom: 6px;
}
@media (max-width: 1023px) {
  .contact-details-block .contact-details-list li {
    min-width: 0;
    padding: 10px 5px;
  }
}
@media (max-width: 767px) {
  .contact-details-block .contact-details-list li {
    border: 0;
    margin-bottom: 0;
    padding: 15px 0;
    position: relative;
  }
}
.contact-details-block .contact-details-list li:last-child,
.contact-details-block .contact-details-list li:nth-child(4n) {
  border: 0;
}
@media (max-width: 767px) {
  .contact-details-block .contact-details-list li:last-child:before,
  .contact-details-block .contact-details-list li:nth-child(4n):before {
    content: none;
  }
}
@media (max-width: 767px) {
  .contact-details-block .contact-details-list li:before {
    content: '';
    width: 41px;
    height: 1px;
    background-color: #deb372;
    position: absolute;
    bottom: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
  }
}
.contact-details-block .contact-details-list a {
  color: #191919;
}
.contact-details-block .contact-details-list a:hover {
  color: #deb372;
}
@media (max-width: 767px) {
  .contact-details-block .contact-details-list a.underlined-text {
    text-decoration: none;
  }
}
.contact-details-block .contact-details-list a i {
  display: inline-block;
  vertical-align: baseline;
  margin-right: 7px;
}
@media (max-width: 767px) {
  .contact-details-block .contact-details-list a i {
    font-size: 1.4em;
  }
}
.contact-details-block .contact-details-list a .fa-phone-alt {
  font-size: 0.7em;
  margin-top: -1px;
}
@media (max-width: 767px) {
  .contact-details-block .contact-details-list a .fa-phone-alt {
    font-size: 1.1em;
  }
}
.contact-details-block .contact-details-list .icon-img {
  max-width: 17px;
}
#footer.upd_footer {
  background-color: transparent;
  border: 0;
  position: relative;
  padding: 10px 0 0;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  color: #191919;
}
#footer.upd_footer .container {
  max-width: 1164px;
}
.upd_footer .footer-top-block {
  background-image: url(../images/img-24.png);
  background-size: 100% 100%;
  background-position: top;
  background-repeat: no-repeat;
  padding: 86px 0 74px;
}
@media (max-width: 1023px) {
  .upd_footer .footer-top-block {
    padding-bottom: 20px;
  }
}
@media (max-width: 767px) {
  .upd_footer .footer-top-block {
    padding: 47px 0 22px;
    background-size: auto 100%;
    overflow: hidden;
    text-align: center;
  }
}
.upd_footer .footer-top-block .text-block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 19.5px;
  line-height: 1.24;
  padding-bottom: 34px;
}
@media (max-width: 767px) {
  .upd_footer .footer-top-block .text-block {
    display: block;
    font-size: 15px;
    line-height: 1.3;
    padding: 0 35px 43px;
  }
}
.upd_footer .footer-top-block .text-block .logo {
  display: inline-block;
  vertical-align: middle;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  margin-right: 13px;
  max-width: 173px;
}
@media (max-width: 767px) {
  .upd_footer .footer-top-block .text-block .logo {
    margin: 0 0 15px;
    max-width: 194px;
  }
}
.upd_footer .footer-top-block .text-block .text-holder {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  padding-top: 50px;
}
@media (max-width: 767px) {
  .upd_footer .footer-top-block .text-block .text-holder {
    padding-top: 0;
  }
}
.upd_footer .footer-top-block .text-block .text-holder p {
  margin-bottom: 0;
}
@media (max-width: 767px) {
  .upd_footer .footer-top-block .text-block .text-holder p {
    margin-bottom: 20px;
  }
}
@media (max-width: 767px) {
  .upd_footer .footer-top-block .text-block .text-holder p:last-child {
    margin-bottom: 0;
  }
}
.upd_footer .footer-bottom-block {
  padding: 49px 0 39px;
  background-color: #191919;
  color: #fff;
  font-size: 15px;
}
@media (max-width: 767px) {
  .upd_footer .footer-bottom-block {
    padding: 43px 0 39px;
    font-size: 14px;
  }
}
.upd_footer .footer-bottom-block a {
  color: inherit;
}
.upd_footer .footer-bottom-block a:hover {
  color: #deb372;
}
.footer-nav-upd {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  font-weight: 500;
  font-size: 18px;
  border: solid #191919;
  border-width: 2px 0 0;
  padding: 12px 0 0;
}
@media (max-width: 1023px) {
  .footer-nav-upd {
    margin: 0 -10px;
    padding: 20px 0 0;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
}
@media (max-width: 767px) {
  .footer-nav-upd {
    border: 0;
    position: relative;
    font-size: 15px;
    max-width: 290px;
    margin: 0 auto;
    padding: 31px 0 0;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    display: block;
    -webkit-columns: 2;
    columns: 2;
    -webkit-column-gap: 30px;
    column-gap: 30px;
  }
}
@media (max-width: 767px) {
  .footer-nav-upd:before {
    content: '';
    width: calc(100% - 20px);
    height: 2px;
    background-color: #191919;
    position: absolute;
    top: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
  }
}
.footer-nav-upd li {
  margin-bottom: 10px;
  text-align: left;
}
@media (max-width: 1023px) {
  .footer-nav-upd li {
    padding: 0 10px;
    width: 20%;
  }
}
@media (max-width: 767px) {
  .footer-nav-upd li {
    width: 50%;
    margin-bottom: 16px;
    width: auto;
  }
}
.footer-nav-upd a {
  color: inherit;
}
.footer-nav-upd a:hover {
  color: #898989;
}
.copyright-upd-holder {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
@media (max-width: 767px) {
  .copyright-upd-holder {
    display: block;
    text-align: center;
  }
}
.copyright-upd-holder .copyright-upd,
.copyright-upd-holder .network-upd-holder {
  margin-bottom: 10px;
}
@media (max-width: 767px) {
  .copyright-upd-holder .copyright-upd,
  .copyright-upd-holder .network-upd-holder {
    margin-bottom: 7px;
  }
}
.copyright-upd-holder .copyright-upd p {
  margin-bottom: 0;
}
.network-upd-holder strong {
  font-weight: 400;
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
}
@media (max-width: 767px) {
  .network-upd-holder strong {
    margin-right: 10px;
  }
}
.network-upd-holder .network-upd {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  vertical-align: middle;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  font-size: 1.6em;
}
.network-upd-holder .network-upd li {
  margin-right: 10px;
}
.upd-popup-holder {
  -webkit-transition:
    opacity 0.4s linear,
    visibility 0.4s linear;
  -o-transition:
    opacity 0.4s linear,
    visibility 0.4s linear;
  transition:
    opacity 0.4s linear,
    visibility 0.4s linear;
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  opacity: 0;
  visibility: hidden;
}
.upd-popup-holder.popup-open {
  opacity: 1;
  visibility: visible;
}
.upd-popup-holder .upd-popup-inner {
  position: absolute;
  max-height: 100%;
  width: 100%;
  overflow: auto;
  padding: 50px 10px;
}
@media (max-width: 767px) {
  .upd-popup-holder .upd-popup-inner {
    padding: 30px 10px;
  }
}
.upd-popup-holder .upd-popup {
  background-color: #fff;
  border-radius: 20px;
  max-width: 1052px;
  margin: 0 auto;
  position: relative;
  padding: 70px 62px 15px;
}
@media (max-width: 767px) {
  .upd-popup-holder .upd-popup {
    padding: 30px 20px;
  }
}
.upd-popup-holder .close-popup {
  position: absolute;
  right: 32px;
  top: -48px;
  font-size: 0;
  width: 26px;
  height: 26px;
  background-image: url(../images/icon-13.png);
  background-size: contain;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}
@media (max-width: 767px) {
  .upd-popup-holder .close-popup {
    width: 15px;
    height: 15px;
    top: -20px;
  }
}
.upd-popup-holder .video-holder {
  position: relative;
  padding-top: 49%;
}
@media (max-width: 479px) {
  .upd-popup-holder .video-holder {
    padding-top: 60%;
  }
}
.upd-popup-holder .video-holder .custom-play-btn-holder,
.upd-popup-holder .video-holder iframe,
.upd-popup-holder .video-holder > video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.upd-popup-holder .video-holder video {
  background-color: #000;
}
.popup-slider {
  position: relative;
  padding: 0 18px;
  margin-bottom: 40px;
}
.popup-slider .popup-slide {
  border-radius: 20px;
  overflow: hidden;
}
.popup-slider .slick-arrow {
  font-size: 0;
  outline: 0;
  border: 0;
  background-color: transparent;
  display: block;
  width: 35px;
  height: 35px;
  background-image: url(../images/icon-06.png);
  background-size: contain;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  position: absolute;
  z-index: 10;
  top: 55%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
@media (max-width: 767px) {
  .popup-slider .slick-arrow {
    width: 20px;
    height: 20px;
  }
}
.popup-slider .slick-arrow.slick-prev {
  left: -32px;
}
@media (max-width: 767px) {
  .popup-slider .slick-arrow.slick-prev {
    left: -10px;
  }
}
.popup-slider .slick-arrow.slick-next {
  background-image: url(../images/icon-07.png);
  right: -32px;
}
@media (max-width: 767px) {
  .popup-slider .slick-arrow.slick-next {
    right: -10px;
  }
}
.popup-slider-descr {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.popup-slider-descr a {
  color: inherit;
}
.popup-slider-descr a:hover {
  color: #deb372;
}
.popup-slider-descr .title-link {
  display: inline-block;
  vertical-align: middle;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 20px;
  line-height: 1.2;
  margin-right: 30px;
  margin-bottom: 10px;
}
@media (max-width: 767px) {
  .popup-slider-descr .title-link {
    font-size: 16px;
  }
}
.popup-slider-descr .title-link .icon-img {
  display: inline-block;
  vertical-align: middle;
  max-width: 27px;
  margin-left: 5px;
}
.popup-slider-descr .descr-link-list {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-right: -22px;
  margin-bottom: 10px;
}
.popup-slider-descr .descr-link-list li {
  padding-right: 22px;
}
.popup-slider-descr .descr-link-list a {
  color: rgba(0, 0, 0, 0.5);
  font-size: 13px;
  font-weight: 700;
  line-height: 1.15;
  text-transform: uppercase;
}
.popup-slider-descr .descr-link-list a:hover {
  color: #000;
}
.custom-play-btn-holder {
  position: relative;
}
.custom-play-btn-holder .over-block,
.custom-play-btn-holder video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.custom-play-btn-holder .over-block {
  z-index: 2;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.32);
  text-align: center;
  padding-bottom: 30px;
}
.custom-play-btn-holder .over-block h4 {
  color: #fff;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-weight: 700;
  font-size: 29px;
  text-transform: uppercase;
  margin-bottom: 35px;
}
@media (max-width: 1023px) {
  .custom-play-btn-holder .over-block h4 {
    font-size: 24px;
    margin-bottom: 5px;
  }
}
@media (max-width: 767px) {
  .custom-play-btn-holder .over-block h4 {
    font-size: 16px;
  }
}
.custom-play-btn-holder .over-block .video-play-btn {
  display: inline-block;
  vertical-align: middle;
  font-size: 0;
  width: 101px;
  height: 101px;
  background-image: url(../images/icon-05.png);
  background-size: contain;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}
@media (max-width: 1023px) {
  .custom-play-btn-holder .over-block .video-play-btn {
    width: 50px;
    height: 50px;
  }
}
@media (max-width: 767px) {
  .custom-play-btn-holder .over-block .video-play-btn {
    width: 30px;
    height: 30px;
  }
}
.listing-section {
  padding: 28px 0 21px;
}
.listing {
  margin-left: -10px;
  margin-right: -10px;
  margin-bottom: 0;
}
.listing .list-item {
  padding-left: 10px;
  padding-right: 10px;
}
.listing .list-item.full-width {
  width: 100%;
}
@media (min-width: 1199px) {
  .listing .list-item.third-width {
    width: 33.333%;
  }
}
.custom-dropdown {
  position: relative;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 14px;
}
.custom-dropdown.fixed-active .custom-dropdown-opener {
  color: #b88b48;
}
.custom-dropdown.fixed-active .custom-dropdown-opener .svg-drop-icon .cls-1 {
  stroke: #b88b48;
}
.custom-dropdown.custom-dropdown-active .custom-dropdown-opener:after {
  -webkit-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  transform: rotate(-180deg);
}
.custom-dropdown .custom-dropdown-opener {
  display: block;
  position: relative;
  text-transform: uppercase;
  -webkit-transition: color 0.4s linear;
  -o-transition: color 0.4s linear;
  transition: color 0.4s linear;
}
.custom-dropdown .custom-dropdown-opener:after {
  content: ' \f107';
  font-weight: 900;
  font-family: 'Font Awesome 5 Free';
  -webkit-transition: -webkit-transform 0.4s linear;
  transition: -webkit-transform 0.4s linear;
  -o-transition: transform 0.4s linear;
  transition: transform 0.4s linear;
  transition:
    transform 0.4s linear,
    -webkit-transform 0.4s linear;
  display: inline-block;
  vertical-align: baseline;
  position: relative;
  margin-left: 5px;
}
.custom-dropdown .custom-dropdown-drop {
  display: none;
  position: absolute;
  z-index: 5;
  top: 100%;
  left: 0;
  min-width: 100%;
  padding: 10px 0;
  background-color: #fff;
  -webkit-box-shadow:
    2px 4px 9px 0 rgba(0, 0, 0, 0.1),
    inset 0 0 1px 0 rgba(25, 25, 25, 0.2);
  box-shadow:
    2px 4px 9px 0 rgba(0, 0, 0, 0.1),
    inset 0 0 1px 0 rgba(25, 25, 25, 0.2);
  border-radius: 10px;
  overflow: hidden;
  white-space: nowrap;
}
.custom-dropdown .custom-dropdown-drop a {
  display: block;
  padding: 11px 20px;
}
.custom-dropdown a {
  color: #191919;
}
.category-heading {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: -20px;
}
.category-heading h1 {
  font-size: 24px;
  font-weight: 700;
  color: #191919;
  margin: 0 13px 0 0;
  text-transform: uppercase;
}
@media (min-width: 1023px) {
  .category-heading h1 {
    font-size: 30px;
  }
}
.category-heading h1:after {
  content: ' /';
  display: inline-block;
  margin-left: 16px;
}
.category-filters,
.category-tags-holder,
.category-title-holder,
.category-view-holder {
  margin-bottom: 36px;
}
@media (max-width: 1023px) {
  .category-filters,
  .category-tags-holder,
  .category-title-holder,
  .category-view-holder {
    margin-bottom: 20px;
  }
}
.category-title-holder {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.subcategory-select-holder {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}
.subcategory-select-holder .custom-dropdown {
  font-size: 16px;
  min-width: 320px;
  max-width: 100%;
}
@media (min-width: 1023px) {
  .subcategory-select-holder .custom-dropdown {
    font-size: 18px;
  }
}
@media (max-width: 479px) {
  .subcategory-select-holder .custom-dropdown {
    min-width: 200px;
  }
}
.subcategory-select-holder .custom-dropdown.fixed-active .custom-dropdown-opener {
  background-color: #deb372;
  color: #191919;
}
.subcategory-select-holder .custom-dropdown.custom-dropdown-active .custom-dropdown-opener {
  border-radius: 23px 23px 0 0;
  border-bottom-color: transparent;
}
.subcategory-select-holder .custom-dropdown.custom-dropdown-active .custom-dropdown-drop {
  top: 100%;
}
.subcategory-select-holder .custom-dropdown .custom-dropdown-opener {
  padding: 12px 24px 11px;
  border-radius: 23px;
  border: 1px solid #707070;
  background-color: #fff;
  -webkit-transition:
    border-radius 0.4s linear,
    background 0.4s linear,
    border 0.4s linear;
  -o-transition:
    border-radius 0.4s linear,
    background 0.4s linear,
    border 0.4s linear;
  transition:
    border-radius 0.4s linear,
    background 0.4s linear,
    border 0.4s linear;
  position: relative;
  z-index: 6;
}
.subcategory-select-holder .custom-dropdown .custom-dropdown-opener:after {
  position: absolute;
  top: 12px;
  right: 15px;
}
.subcategory-select-holder .custom-dropdown .custom-dropdown-drop {
  margin-top: -2px;
  border-radius: 0 0 23px 23px;
  border: solid #707070;
  border-width: 0 1px 1px 1px;
  -webkit-box-shadow: 2px 4px 9px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 2px 4px 9px 0 rgba(0, 0, 0, 0.1);
  top: 50%;
  -webkit-transition: top 0.4s linear;
  -o-transition: top 0.4s linear;
  transition: top 0.4s linear;
}
.category-filters {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.category-filters .filter-title {
  font-size: 16px;
  font-weight: 400;
  color: #191919;
  display: inline-block;
  vertical-align: middle;
  margin-right: 51px;
}
.category-filters .filters-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0 -20px;
}
.category-filters .filters-list > li {
  padding: 0 20px;
}
.svg-drop-icon {
  display: inline-block;
  vertical-align: middle;
  margin-left: 2px;
}
.category-tags-holder ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: -10px;
}
.category-tags-holder ul li {
  margin-bottom: 10px;
}
.category-tags-holder .btn {
  margin-right: 6px;
}
.category-tags-holder .btn.btn-outline-secondary.active,
.category-tags-holder .btn.btn-outline-secondary:hover {
  background-color: #deb372;
  border-color: #deb372;
  color: #191919;
}
.category-view-holder {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.category-view-holder .category-view-title {
  font-size: 16px;
  font-weight: 400;
  color: #191919;
  display: inline-block;
  vertical-align: middle;
  margin-right: 22px;
}
.category-view-holder .category-view-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.category-view-holder .category-view-list a {
  display: inline-block;
  vertical-align: middle;
  padding: 8px 7px;
  border-radius: 7px;
  -webkit-transition: background 0.4s linear;
  -o-transition: background 0.4s linear;
  transition: background 0.4s linear;
}
.category-view-holder .category-view-list a.active {
  background-color: #deb372;
}
.category-view-holder .category-view-list a img {
  display: block;
}
.list-item-descr {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0 4px;
}
.list-item-descr h3 {
  font-size: 20px;
  font-weight: 700;
  color: #191919;
  text-transform: uppercase;
  margin: 0 0 4px;
}
.list-item-descr .list-item-name-holder strong {
  font-size: 12px;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  color: #191919;
}
.list-item-descr .price {
  font-size: 28px;
  font-weight: 500;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  color: #deb372;
  white-space: nowrap;
}
.card-preview {
  padding: 5px;
  border-radius: 10px;
  overflow: hidden;
  -webkit-transition:
    color 0.4s linear,
    background 0.4s linear,
    -webkit-box-shadow 0.4s linear;
  transition:
    color 0.4s linear,
    background 0.4s linear,
    -webkit-box-shadow 0.4s linear;
  -o-transition:
    color 0.4s linear,
    background 0.4s linear,
    box-shadow 0.4s linear;
  transition:
    color 0.4s linear,
    background 0.4s linear,
    box-shadow 0.4s linear;
  transition:
    color 0.4s linear,
    background 0.4s linear,
    box-shadow 0.4s linear,
    -webkit-box-shadow 0.4s linear;
}
@media (min-width: 1023px) {
  .card-preview {
    padding: 8px 8px 16px;
  }
}
.card-preview:hover {
  background-color: #fff;
  -webkit-box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.21);
  box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.21);
}
.card-preview .img-holder {
  background-color: transparent;
  padding: 0;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 0;
  position: relative;
  padding-top: 129%;
}
.card-preview .img-holder:after,
.card-preview .img-holder:before {
  content: none;
}
.card-preview .img-holder img {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.card-preview .img-holder .hover-note {
  display: none;
}
.listing-load-more-holder {
  padding: 3px 0;
  text-align: center;
  color: #636363;
  font-size: 16px;
}
.listing-load-more-holder .btn {
  margin-right: 15px;
  color: #191919;
  font-weight: 700;
  font-size: 18px;
  text-transform: none;
  padding: 12px 15px;
  min-width: 165px;
  border-radius: 3em;
}
.best-sellers-section {
  padding: 15px 0 10px;
}
.best-sellers-section .block-heading {
  margin: 0 auto 17px;
}
.best-sellers-section .block-heading .heading {
  font-size: 24px;
  font-weight: 700;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  padding: 0;
}
@media (min-width: 1023px) {
  .best-sellers-section .block-heading .heading {
    font-size: 30px;
  }
}
.block-heading {
  text-align: left;
  max-width: none;
  margin-bottom: 17px;
}
.block-heading:after,
.block-heading:before {
  content: none;
}
.block-heading .heading {
  color: #191919;
  font-size: 24px;
  font-weight: 700;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  padding: 0;
}
@media (min-width: 1023px) {
  .block-heading .heading {
    font-size: 30px;
  }
}
.block-heading .heading:after {
  content: none;
}
.block-heading .heading:before {
  background-color: #deb372;
  left: calc(100% + 27px);
}
.block-heading .heading span:before {
  content: none;
}
.best-seller-slider {
  margin: 0 -8px;
}
.best-seller-slider .slick-list {
  margin: 0 -8px;
  padding: 15px 8px;
}
.cards-template-default {
  background-color: #f5f5f5;
}
.detail-section.detail-section-upd {
  padding: 0 0 30px;
}
@media (max-width: 767px) {
  .detail-section.detail-section-upd {
    padding-bottom: 0;
  }
}
.editor-holder {
  background-color: #fff;
  border: 1px solid #e2b376;
  border-radius: 0 0 10px 10px;
  padding: 28px 20px 16px 45px;
  overflow: hidden;
}
@media (max-width: 767px) {
  .editor-holder {
    padding: 20px 20px;
    border: 0;
    overflow: visible;
    background-color: transparent;
  }
}
@media (max-width: 479px) {
  .editor-holder {
    padding: 33px 0 10px;
  }
}
.editor-holder .editor-card-note {
  padding: 18px 0 0;
  text-align: center;
  color: #e43030;
  font-size: 13px;
}
@media (max-width: 767px) {
  .editor-holder .editor-card-note {
    padding: 15px 0 0;
    font-size: 12px;
    letter-spacing: 0.2px;
  }
}
.editor-holder .editor-card-note:before {
  content: '';
  display: inline-block;
  vertical-align: baseline;
  width: 10px;
  height: 10px;
  background-image: url(../images/icon-23.png);
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
}
.editor-holder .page-heading {
  text-align: left;
  border: 0;
  padding: 0 0 0 10px;
}
@media (max-width: 1023px) {
  .editor-holder .page-heading {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
  }
}
@media (max-width: 767px) {
  .editor-holder .page-heading {
    padding: 0;
    margin-bottom: 15px;
  }
}
.editor-holder .page-heading .block-header {
  font-size: 25px;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-weight: 700;
  letter-spacing: 0;
}
@media (max-width: 1023px) {
  .editor-holder .page-heading .block-header {
    margin-bottom: 0;
  }
}
@media (max-width: 767px) {
  .editor-holder .page-heading .block-header {
    font-size: 19px;
    line-height: 1.4;
    letter-spacing: 0.7px;
  }
}
.editor-holder .page-heading .price-holder {
  font-size: 25px;
}
@media (max-width: 767px) {
  .editor-holder .page-heading .price-holder {
    font-size: 15px;
    margin-bottom: 3px;
  }
}
.editor-holder .page-heading .price-holder strong {
  font-weight: 400;
}
.editor-holder .detail-holder {
  background-color: transparent;
  border: 0;
  border-radius: 0;
  padding: 0;
}
.editor-holder .preview-block {
  position: relative;
  z-index: 5;
  width: 51.5%;
}
@media (max-width: 767px) {
  .editor-holder .preview-block {
    width: 100%;
  }
}
@media (max-width: 479px) {
  .editor-holder .preview-block {
    margin-bottom: 12px;
  }
}
.editor-holder .preview-block .card-bg-options {
  position: absolute;
  top: 10px;
  right: 10px;
}
@media (min-width: 1023px) {
  .editor-holder .preview-block .card-bg-options {
    top: 23px;
    right: 24px;
  }
}
.editor-holder .details-block {
  padding: 0;
  width: 44%;
}
@media (max-width: 767px) {
  .editor-holder .details-block {
    width: 100%;
  }
}
.editor-holder .editor-note-holder {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 12px 0 8px;
  margin-bottom: 21px;
  position: relative;
}
@media (max-width: 767px) {
  .editor-holder .editor-note-holder {
    display: block;
    padding: 14px 0 8px;
    text-align: center;
  }
}
.editor-holder .editor-note-holder:before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  width: 200%;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  background-color: rgba(222, 179, 114, 0.2);
}
.editor-holder .editor-note-holder > span,
.editor-holder .editor-note-holder > strong {
  display: block;
  position: relative;
  z-index: 2;
}
.editor-holder .editor-note-holder > strong {
  font-size: 20px;
  font-weight: 500;
}
@media (max-width: 767px) {
  .editor-holder .editor-note-holder > strong {
    margin-bottom: 9px;
  }
}
.editor-holder .editor-note-holder > span {
  font-size: 11px;
  font-weight: 500;
}
@media (max-width: 767px) {
  .editor-holder .editor-note-holder > span {
    font-size: 12px;
    letter-spacing: 0.2px;
  }
}
@media (max-width: 767px) {
  .editor-holder .editor-note-holder > span:after,
  .editor-holder .editor-note-holder > span:before {
    display: inline-block;
    margin-left: 10px;
    -webkit-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    transform: rotate(-90deg);
  }
}
@media (max-width: 767px) {
  .editor-holder .editor-note-holder > span:before {
    content: '> ';
    margin-left: 0;
    margin-right: 10px;
  }
}
.editor-holder .editor-note-holder > span:after {
  content: ' >';
}
.editor-holder .editor-music-holder {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0 7px;
  margin-bottom: 22px;
}
@media (max-width: 767px) {
  .editor-holder .editor-music-holder {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 19px;
  }
}
.editor-holder .editor-music-holder .hidden-fields-holder,
.editor-holder .editor-music-holder .music-holder {
  margin-bottom: 15px;
  max-width: 50%;
}
.editor-holder .editor-music-holder .hidden-fields-holder {
  padding: 10px 32px 0 0;
  margin: 0;
}
.editor-holder .editor-music-holder .hidden-fields-holder > label {
  padding-left: 0;
  padding-right: 2px;
  position: relative;
  font-size: 20px;
}
@media (max-width: 767px) {
  .editor-holder .editor-music-holder .hidden-fields-holder > label {
    font-size: 15px;
  }
}
.editor-holder .editor-music-holder .hidden-fields-holder .fake-checkbox-holder {
  position: static;
}
.editor-holder .editor-music-holder .hidden-fields-holder .fake-checkbox {
  margin-right: 0;
  margin-left: 8px;
  left: auto;
  right: -32px;
}
@media (max-width: 767px) {
  .editor-holder .editor-music-holder .hidden-fields-holder .fake-checkbox {
    width: 18px;
    height: 18px;
    top: -1px;
    right: -26px;
  }
}
.editor-holder .editor-music-holder .hidden-fields-holder .fake-checkbox:before {
  color: #deb372;
}
@media (max-width: 767px) {
  .editor-holder .editor-music-holder .hidden-fields-holder .fake-checkbox:before {
    font-size: 16px;
  }
}
.editor-holder .music-holder {
  padding: 0;
}
.editor-holder .music-holder > label {
  background-color: #deb372;
  border-radius: 3em;
  padding: 10px 25px;
  text-align: center;
  font-size: 20px;
  text-transform: uppercase;
  -webkit-transition: background 0.4s linear;
  -o-transition: background 0.4s linear;
  transition: background 0.4s linear;
}
@media (max-width: 767px) {
  .editor-holder .music-holder > label {
    font-size: 16px;
  }
}
.editor-holder .music-holder > label:hover {
  background-color: rgba(222, 179, 114, 0.8);
}
.editor-holder .music-holder > label .hide-input:checked + .fake-checkbox {
  background-color: #000;
}
.editor-holder .music-holder > label .fake-checkbox {
  left: auto;
  top: auto;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 21px;
  height: 21px;
  border-radius: 50%;
  background-color: #fff;
}
.editor-holder .music-holder > label .fake-checkbox:before {
  content: '\f00c';
  color: #fff;
  font-family: 'Font Awesome 5 Free' !important;
  font-weight: 900;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.editor-holder .music-holder .popup-block {
  left: 0;
  max-width: 340px;
  width: 340px;
  border-radius: 23px;
  border: 1px solid #707070;
  -webkit-box-shadow: 2px 4px 9px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 2px 4px 9px 0 rgba(0, 0, 0, 0.16);
  padding: 21px 20px 21px 25px;
}
@media (max-width: 767px) {
  .editor-holder .music-holder .popup-block {
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    max-width: calc(100vw - 30px);
  }
}
.editor-holder .music-holder .popup-block h3 {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  display: inline-block;
  vertical-align: middle;
  border: solid #deb372;
  border-width: 0 0 1px;
  padding: 0 4px 4px;
}
.editor-holder .music-holder .popup-block .close-popup {
  color: #deb372;
  font-size: 20px;
  display: block;
  position: absolute;
  top: 18px;
  right: 24px;
  -webkit-transition: opacity 0.4s linear;
  -o-transition: opacity 0.4s linear;
  transition: opacity 0.4s linear;
}
.editor-holder .music-holder .popup-block .close-popup:hover {
  opacity: 0.8;
}
.editor-holder .music-holder .popup-block .popup-content {
  padding: 0;
}
.editor-holder .music-holder .popup-block .popup-heading {
  border: 0;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 0 0 21px;
}
.editor-holder .music-holder .popup-block .popup-heading .decor {
  display: none;
}
.editor-holder .music-holder .popup-block .btn-holder {
  margin: 22px 0 0;
}
.editor-holder .music-holder .popup-block .btn-holder .btn {
  min-width: 112px;
  border-radius: 3em;
  font-weight: 500;
  font-size: 20px;
  text-transform: uppercase;
  padding: 10px;
}
.editor-holder .music-holder .current-song {
  padding: 4px 5px 0;
}
.editor-holder .audio-list {
  max-height: 284px;
}
.editor-holder .audio-list::-webkit-scrollbar {
  width: 8px;
}
.editor-holder .audio-list::-webkit-scrollbar-track {
  background-color: #dadada;
  border-radius: 10px;
}
.editor-holder .audio-list::-webkit-scrollbar-thumb {
  background-color: #636363;
  border-radius: 10px;
  background-clip: padding-box;
  border: 2px solid transparent;
}
.editor-holder .audio-list::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}
.editor-holder .audio-list li {
  background-color: #f2f2f2;
  padding: 0;
  border: 0;
  border-radius: 3em;
  position: relative;
  margin-bottom: 11px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.editor-holder .audio-list li .song-block {
  width: 57px;
  height: 57px;
  border-radius: 50%;
  background-color: #deb372;
  position: relative;
}
.editor-holder .audio-list li .song-block.playing:before {
  content: '\f04c';
}
.editor-holder .audio-list li .song-block.playing + label .chart .outer {
  -webkit-animation-play-state: running !important;
  animation-play-state: running !important;
}
.editor-holder .audio-list li .song-block:before {
  content: '\f04b';
  font-family: 'Font Awesome 5 Free' !important;
  font-weight: 900;
}
.editor-holder .audio-list li .song-block .chart {
  display: none;
}
.editor-holder .audio-list li label {
  padding-left: 12px;
}
.editor-holder .audio-list li label .chart svg {
  border-radius: 0;
  background: #dadada;
}
.editor-holder .audio-list li label .chart .outer {
  stroke-dasharray: 157;
  stroke-dashoffset: 157;
  -webkit-animation-play-state: paused !important;
  animation-play-state: paused !important;
}
.editor-holder .audio-list li label .title {
  font-size: 11px;
  font-weight: 500;
  color: #191919;
  display: block;
  margin-bottom: 8px;
}
.editor-holder .audio-list li label .fake-checkbox-holder {
  position: static;
  font-size: 0;
  padding: 0;
}
.editor-holder .audio-list li label .fake-checkbox-holder .hide-input:checked + .fake-checkbox {
  background-color: #deb372;
  border-color: #deb372;
}
.editor-holder .audio-list li label .fake-checkbox-holder .fake-checkbox {
  width: 24px;
  height: 24px;
  margin: 0;
  top: 50%;
  left: auto;
  right: 18px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.editor-holder .audio-list li label .fake-checkbox-holder .fake-checkbox:before {
  content: '\f00c';
  color: #fff;
  font-family: 'Font Awesome 5 Free' !important;
  font-weight: 900;
  position: absolute;
  display: block;
  top: 55%;
  left: 50%;
  right: auto;
  bottom: auto;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background-color: transparent;
}
.editor-holder .fake-checkbox {
  border: 1px solid #191919;
}
.editor-holder .content-details {
  padding: 0 18px 0 8px;
  max-height: 482px;
  margin-bottom: 25px;
}
@media (max-width: 767px) {
  .editor-holder .content-details {
    padding: 0;
    margin-bottom: 2px;
  }
}
.editor-holder .content-details:last-child {
  margin-bottom: 0;
}
.editor-holder .content-details::-webkit-scrollbar {
  width: 8px;
}
.editor-holder .content-details::-webkit-scrollbar-track {
  background-color: #dadada;
  border-radius: 10px;
}
.editor-holder .content-details::-webkit-scrollbar-thumb {
  background-color: #636363;
  border-radius: 10px;
  background-clip: padding-box;
  border: 2px solid transparent;
}
.editor-holder .content-details::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}
.editor-holder .content-details .input-holder {
  margin-bottom: 9px;
  position: relative;
}
@media (max-width: 767px) {
  .editor-holder .content-details .input-holder {
    margin-bottom: 11px;
  }
}
.editor-holder .content-details .input-holder.hidden-field .content-field {
  background-color: rgba(222, 179, 114, 0.2);
}
.editor-holder .content-details .input-holder .reset-link {
  position: absolute;
  top: 50%;
  left: 10px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 9px;
  color: #deb372;
  text-decoration: underline;
  text-transform: uppercase;
}
@media (max-width: 767px) {
  .editor-holder .content-details .input-holder .reset-link {
    left: 16px;
  }
}
.editor-holder .content-details .input-holder .reset-link:hover {
  text-decoration: none;
}
@media (max-width: 767px) {
  .editor-holder .content-details .input-holder .reset-link.clear-state,
  .editor-holder .content-details .input-holder .reset-link.reset-state {
    font-size: 0;
    height: 16px;
    width: 40px;
    background-size: contain;
    background-position: left;
    background-repeat: no-repeat;
  }
}
@media (max-width: 767px) {
  .editor-holder .content-details .input-holder .reset-link.clear-state {
    background-image: url(../images/icon-24.png);
  }
}
@media (max-width: 767px) {
  .editor-holder .content-details .input-holder .reset-link.reset-state {
    background-image: url(../images/icon-25.png);
  }
}
.editor-holder .content-details .input-holder .keyboard-link,
.editor-holder .content-details .input-holder .keyboardInputInitiator {
  font-size: 0;
  position: absolute;
  top: 55%;
  right: 11px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  display: inline-block;
  vertical-align: middle;
  width: 23px;
  height: 23px;
  background-image: url(../images/icon-20.png);
  background-size: contain;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  -webkit-transition: opacity 0.4s linear;
  -o-transition: opacity 0.4s linear;
  transition: opacity 0.4s linear;
}
.editor-holder .content-details .input-holder .keyboard-link:hover,
.editor-holder .content-details .input-holder .keyboardInputInitiator:hover {
  opacity: 0.8;
}
.editor-holder .content-details .input-holder .keyboardInputInitiator {
  height: auto;
}

/* Removed to make the on-screen keyboard permanent 
@media (max-width: 767px) {
  .editor-holder .content-details .input-holder .keyboardInputInitiator {
    display: none !important;
  }
} */
.editor-holder .content-details .content-field[type='text'],
.editor-holder .content-details .content-promo[type='text'] {
  padding: 11px 10px;
  padding-left: 75px;
  padding-right: 45px;
  height: 40px;
}
@media (max-width: 767px) {
  .editor-holder .content-details .content-field[type='text'],
  .editor-holder .content-details .content-promo[type='text'] {
    font-size: 16px;
    padding: 13px 45px;
    height: 46px;
    border-radius: 3em;
  }
}
.editor-holder .editor-btn-holder {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-right: 8px;
}
@media (max-width: 767px) {
  .editor-holder .editor-btn-holder {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}
.editor-holder .editor-btn-holder .draft-link {
  color: #191919;
  font-weight: 700;
  font-size: 18px;
  margin-right: 14px;
}
@media (max-width: 767px) {
  .editor-holder .editor-btn-holder .draft-link {
    margin-right: 0;
    margin-bottom: 10px;
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10;
    display: block;
    width: 100%;
    border: 2px solid #deb372;
    border-radius: 3em;
    padding: 11px 10px;
    font-size: 16px;
  }
}
.editor-holder .editor-btn-holder .draft-link:hover {
  color: #deb372;
}
.editor-holder .editor-btn-holder .draft-link img {
  display: inline-block;
  vertical-align: bottom;
  margin-left: 7px;
  max-width: 18px;
}
.editor-holder .editor-btn-holder .price {
  font-weight: 500;
  font-size: 28px;
  display: inline-block;
  vertical-align: middle;
}
@media (max-width: 767px) {
  .editor-holder .editor-btn-holder .price {
    margin-bottom: 28px;
  }
}
.editor-holder .editor-btn-holder .btn {
  border-radius: 3em;
  font-size: 18px;
  font-weight: 700;
  padding: 11px 7px;
  min-width: 193px;
  font-family: Roboto, Arial, Helvetica, sans-serif;
}
@media (max-width: 767px) {
  .editor-holder .editor-btn-holder .btn {
    display: block;
    width: 100%;
    margin-bottom: 13px;
  }
}
.editor-holder .editor-btn-holder .btn img {
  display: inline-block;
  vertical-align: baseline;
  margin-left: 10px;
  max-width: 20px;
}
.card-bg-options {
  position: relative;
}
.card-bg-options.drop-active .variant-opener:after,
.card-bg-options.drop-active .variant-opener:before {
  opacity: 1;
}
.card-bg-options.drop-active .variant-opener > * {
  opacity: 0;
}
.card-bg-options.drop-active .card-bg-option-drop {
  opacity: 1;
  visibility: visible;
}
.card-bg-options .variant-opener {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: relative;
  width: 30px;
  height: 30px;
  border: 1px solid #fff;
  border-radius: 50%;
  background-color: #deb372;
  -webkit-transition: opacity 0.4s linear;
  -o-transition: opacity 0.4s linear;
  transition: opacity 0.4s linear;
}
@media (min-width: 1023px) {
  .card-bg-options .variant-opener {
    width: 63px;
    height: 63px;
  }
}
.card-bg-options .variant-opener:hover {
  opacity: 0.8;
}
.card-bg-options .variant-opener:after,
.card-bg-options .variant-opener:before {
  content: '';
  display: block;
  width: 2px;
  height: 14px;
  background-color: #191919;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%) rotate(45deg);
  -ms-transform: translate(-50%, -50%) rotate(45deg);
  transform: translate(-50%, -50%) rotate(45deg);
  -webkit-transition: opacity 0.4s linear;
  -o-transition: opacity 0.4s linear;
  transition: opacity 0.4s linear;
  opacity: 0;
}
@media (min-width: 1023px) {
  .card-bg-options .variant-opener:after,
  .card-bg-options .variant-opener:before {
    height: 30px;
  }
}
.card-bg-options .variant-opener:after {
  -webkit-transform: translate(-50%, -50%) rotate(-45deg);
  -ms-transform: translate(-50%, -50%) rotate(-45deg);
  transform: translate(-50%, -50%) rotate(-45deg);
}
.card-bg-options .variant-opener > * {
  -webkit-transition: opacity 0.4s linear;
  -o-transition: opacity 0.4s linear;
  transition: opacity 0.4s linear;
}
.card-bg-options .card-bg-option-drop {
  position: absolute;
  top: calc(100% + 13px);
  right: 0;
  background-color: #deb372;
  border: 1px solid #fff;
  border-radius: 18px;
  padding: 18px 11px;
  text-align: center;
  -webkit-transition:
    opacity 0.4s linear,
    visibility 0.4s linear;
  -o-transition:
    opacity 0.4s linear,
    visibility 0.4s linear;
  transition:
    opacity 0.4s linear,
    visibility 0.4s linear;
  opacity: 0;
  visibility: hidden;
}
.card-bg-options .card-bg-option-drop .card-bg-option-list {
  max-height: 562px;
  overflow: auto;
}
.card-bg-options .card-bg-option-drop .card-bg-option-list::-webkit-scrollbar {
  width: 0;
}
.card-bg-options .card-bg-option-drop .card-bg-option-list::-webkit-scrollbar-track {
  background-color: #dadada;
  border-radius: 10px;
}
.card-bg-options .card-bg-option-drop .card-bg-option-list::-webkit-scrollbar-thumb {
  background-color: #636363;
  border-radius: 10px;
  background-clip: padding-box;
  border: 2px solid transparent;
}
.card-bg-options .card-bg-option-drop .card-bg-option-list::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}
.card-bg-options .card-bg-option-drop .card-bg-option-list li {
  margin-bottom: 5px;
}
.card-bg-options .card-bg-option-drop .img-holder {
  width: 133px;
  position: relative;
}
.card-bg-options .card-bg-option-drop .img-holder.active-variant:before {
  opacity: 1;
  visibility: visible;
}
.card-bg-options .card-bg-option-drop .img-holder:before {
  content: '\f00c';
  color: #fff;
  font-family: 'Font Awesome 5 Free' !important;
  font-weight: 900;
  font-size: 24px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 57px;
  height: 57px;
  border-radius: 50%;
  background-color: rgba(25, 25, 25, 0.8);
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition:
    opacity 0.4s linear,
    visibility 0.4s linear;
  -o-transition:
    opacity 0.4s linear,
    visibility 0.4s linear;
  transition:
    opacity 0.4s linear,
    visibility 0.4s linear;
  opacity: 0;
  visibility: hidden;
}
.card-bg-options .card-bg-option-drop .img-holder img {
  cursor: pointer;
}
.card-bg-options .card-bg-option-drop .variant-title {
  display: block;
  font-size: 13px;
  padding: 11px 0;
}
.custom-icon {
  display: inline-block;
  vertical-align: middle;
  width: 16px;
  height: 16px;
  position: relative;
}
@media (min-width: 1023px) {
  .custom-icon {
    width: 30px;
    height: 30px;
  }
}
.custom-icon .custom-icon-inner:after,
.custom-icon .custom-icon-inner:before,
.custom-icon:after,
.custom-icon:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 6px;
  border: 1px solid #191919;
  border-radius: 3em;
}
@media (min-width: 1023px) {
  .custom-icon .custom-icon-inner:after,
  .custom-icon .custom-icon-inner:before,
  .custom-icon:after,
  .custom-icon:before {
    height: 12px;
    border-width: 2px;
  }
}
.custom-icon .custom-icon-inner:after,
.custom-icon:after {
  width: 6px;
}
@media (min-width: 1023px) {
  .custom-icon .custom-icon-inner:after,
  .custom-icon:after {
    width: 12px;
  }
}
.custom-icon:after,
.custom-icon:before {
  top: 0;
  left: 0;
}
.custom-icon .custom-icon-inner:after,
.custom-icon .custom-icon-inner:before {
  bottom: 0;
  right: 0;
}
.custom-motion-mode .nav .menu-bottom > li.active-page > a {
  color: #e43030;
}
.custom-motion-mode #header.header {
  padding: 30px 0 8px;
}
@media (max-width: 1023px) {
  .custom-motion-mode #header.header {
    padding: 10px 0 0;
  }
}
.custom-motion-mode #header_placeholder {
  display: none;
}
@media (max-width: 1023px) {
  .custom-motion-mode #header_placeholder {
    display: block;
    min-height: 114px;
  }
}
.custom-motion-mode .header {
  border-color: #e43030;
}
.custom-motion-mode .header .logo {
  max-width: 166px;
  margin-right: 7px;
}
@media (max-width: 1023px) {
  .custom-motion-mode .header .logo {
    max-width: 110px;
  }
}
.custom-motion-mode .header .container {
  max-width: 1198px;
}
.custom-motion-mode .header .main-nav-holder > li > .opener {
  padding: 15px 10px 9px;
}
.custom-motion-mode .header .main-menu-holder {
  border: 0;
}
.custom-motion-mode .header .main-menu-holder .nav-holder .navbar {
  margin-right: 8px;
}
.custom-motion-mode .header .main-menu-holder .btn-danger {
  position: relative;
  padding-left: 30px;
  padding-right: 30px;
}
.custom-motion-mode .header .main-menu-holder .btn-danger:before {
  content: '\f00d';
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  font-size: 0.95em;
  position: absolute;
  top: 50%;
  left: 18px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.custom-motion-mode .header-bottom {
  display: none !important;
}
.custom-motion-mode .mobile-preview-block .banner-title {
  color: #fff;
}
@media (max-width: 767px) {
  .custom-motion-mode .banner-section.upd_section {
    display: block;
  }
}
.custom-motion-mode .banner-section.upd_section .banner {
  padding: 25px 0 13px;
  min-height: 0;
}
@media (max-width: 767px) {
  .custom-motion-mode .banner-section.upd_section .banner {
    height: auto;
  }
}
.custom-motion-mode .banner-section.upd_section .banner:after {
  min-height: 401px;
}
.custom-motion-mode .banner-section.upd_section .banner-content {
  display: block;
  text-align: center;
  color: #fff;
  font-size: 20px;
}
.custom-motion-mode .banner-section.upd_section .banner-content .banner-title {
  color: #fff;
  text-transform: none;
  margin-bottom: 15px;
}
.custom-motion-mode .banner-section.upd_section .banner-content p {
  margin-bottom: 35px;
}
.custom-motion-mode .banner-section.upd_section .banner-content .btn.btn-outline {
  background-color: transparent;
  border-color: #fff;
  font-size: 20px;
  color: #fff;
  text-transform: uppercase;
  border-radius: 3em;
  padding: 17px 8px;
  min-width: 292px;
  -webkit-transition:
    color 0.4s linear,
    background 0.4s linear,
    border 0.4s linear;
  -o-transition:
    color 0.4s linear,
    background 0.4s linear,
    border 0.4s linear;
  transition:
    color 0.4s linear,
    background 0.4s linear,
    border 0.4s linear;
}
.custom-motion-mode .banner-section.upd_section .banner-content .btn.btn-outline:hover {
  background-color: #e43030;
  border-color: #e43030;
}
.custom-motion-mode .banner-section.upd_section .banner-content .btn.btn-outline i {
  display: inline-block;
  margin-left: 16px;
}
.custom-motion-mode .editor-holder {
  border-color: #e43030;
  background-image: url(../images/img-42.png);
  background-size: contain;
  background-position: top;
  background-repeat: no-repeat;
  padding-top: 30px;
  padding-bottom: 34px;
}
.custom-motion-mode .editor-holder .preview-block {
  text-align: center;
  width: 53%;
}
@media (max-width: 767px) {
  .custom-motion-mode .editor-holder .preview-block {
    width: 100%;
  }
}
.custom-motion-mode .editor-holder .preview-block img {
  margin: 0 auto;
}
.custom-motion-mode .editor-holder .preview-block .editor-card-note {
  font-size: 15px;
  padding: 23px 0 4px;
}
.custom-motion-mode .editor-holder .preview-block .editor-card-note:before {
  width: 12px;
  height: 12px;
}
.custom-motion-mode .editor-holder .details-block {
  width: 47%;
}
@media (max-width: 767px) {
  .custom-motion-mode .editor-holder .details-block {
    width: 100%;
  }
}
.custom-motion-mode .editor-holder .page-heading {
  padding: 0 0 14px;
  margin-bottom: 40px;
  border: solid #191919;
  border-width: 0 0 1px;
}
.custom-motion-mode .editor-holder .page-heading .block-header {
  text-transform: uppercase;
}
.custom-motion-mode .editor-holder .page-heading .price-holder {
  font-size: 20px;
  font-weight: 400;
}
.custom-motion-mode .editor-holder .editor-music-holder {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.custom-motion-mode .editor-holder .music-holder {
  margin-bottom: 5px;
}
@media (max-width: 767px) {
  .custom-motion-mode .editor-holder .music-holder {
    width: 100%;
  }
}
.custom-motion-mode .editor-holder .music-holder.active > label:before {
  -webkit-transform: translateY(-50%) rotate(-180deg);
  -ms-transform: translateY(-50%) rotate(-180deg);
  transform: translateY(-50%) rotate(-180deg);
}
.custom-motion-mode .editor-holder .music-holder > label {
  padding: 8px 27px;
  min-width: 354px;
  max-width: 100%;
  background-color: #e43030;
  color: #fff;
  position: relative;
}
@media (max-width: 767px) {
  .custom-motion-mode .editor-holder .music-holder > label {
    min-width: 0;
    width: 100%;
  }
}
.custom-motion-mode .editor-holder .music-holder > label:before {
  content: '\f107';
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  position: absolute;
  top: 50%;
  right: 20px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: -webkit-transform 0.1s linear;
  transition: -webkit-transform 0.1s linear;
  -o-transition: transform 0.1s linear;
  transition: transform 0.1s linear;
  transition:
    transform 0.1s linear,
    -webkit-transform 0.1s linear;
}
.custom-motion-mode .editor-holder .music-holder > label .fake-checkbox-holder {
  display: none;
}
.custom-motion-mode .editor-holder .music-holder .popup-block {
  width: 100%;
  max-width: 100%;
}
.custom-motion-mode .editor-holder .music-holder .popup-block h3 {
  border-color: #e43030;
}
.custom-motion-mode .editor-holder .music-holder .popup-block .close-popup {
  color: #e43030;
}
.custom-motion-mode .editor-holder .music-holder .popup-block .btn-holder .btn.btn-fill,
.custom-motion-mode
  .editor-holder
  .music-holder
  .popup-block
  .btn-holder
  .btn[type='button'].btn-fill,
.custom-motion-mode
  .editor-holder
  .music-holder
  .popup-block
  .btn-holder
  .btn[type='submit'].btn-fill {
  background-color: #e43030;
  border-color: #e43030;
  color: #fff;
}
.custom-motion-mode .editor-holder .music-holder .popup-block .btn-holder .btn.btn-fill:hover,
.custom-motion-mode
  .editor-holder
  .music-holder
  .popup-block
  .btn-holder
  .btn[type='button'].btn-fill:hover,
.custom-motion-mode
  .editor-holder
  .music-holder
  .popup-block
  .btn-holder
  .btn[type='submit'].btn-fill:hover {
  background-color: #fff;
  color: #e43030;
}
.custom-motion-mode .editor-holder .music-holder .current-song .song-title {
  color: #e43030;
}
.custom-motion-mode
  .editor-holder
  .audio-list
  li
  label
  .fake-checkbox-holder
  .hide-input:checked
  + .fake-checkbox {
  background-color: #e43030;
  border-color: #e43030;
}
.custom-motion-mode .editor-holder .audio-list li .song-block {
  background-color: #e43030;
}
.custom-motion-mode .editor-holder .audio-list .chart .outer {
  stroke: #e43030;
}
.custom-motion-mode .editor-holder .content-field {
  padding: 11px 19px;
  height: 40px;
  display: block;
  width: 100%;
  border: 1px solid #090509;
  border-radius: 19px;
  outline: 0;
  -webkit-transition: border 0.1s linear;
  -o-transition: border 0.1s linear;
  transition: border 0.1s linear;
  color: #333;
  font-size: 13px;
  font-weight: 400;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  margin: 0 auto;
}
@media (max-width: 767px) {
  .custom-motion-mode .editor-holder .content-field {
    height: 46px;
    border-radius: 3em;
  }
}
.custom-motion-mode .editor-holder .content-field::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.3);
}
.custom-motion-mode .editor-holder .content-field::-moz-placeholder {
  color: rgba(0, 0, 0, 0.3);
}
.custom-motion-mode .editor-holder .content-field:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.3);
}
.custom-motion-mode .editor-holder .content-field:-moz-placeholder {
  color: rgba(0, 0, 0, 0.3);
}
.custom-motion-mode .editor-holder .content-field.keyboardInput {
  padding-right: 45px;
}
.custom-motion-mode .editor-holder .contact-details-holder {
  max-width: 325px;
  margin: 0 auto 32px;
  overflow: visible;
  max-height: none;
}
.custom-motion-mode .editor-holder .contact-details-holder .contact-details {
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 15px;
}
.custom-motion-mode .editor-holder .contact-details-holder .reset-link {
  display: none !important;
}
.custom-motion-mode .editor-holder .contact-details-holder .content-field[type='text'],
.custom-motion-mode .editor-holder .contact-details-holder .content-promo[type='text'] {
  padding: 11px 19px;
  text-align: left;
}
.custom-motion-mode
  .editor-holder
  .contact-details-holder
  .content-field[type='text'].keyboardInput,
.custom-motion-mode
  .editor-holder
  .contact-details-holder
  .content-promo[type='text'].keyboardInput {
  padding-right: 45px;
}
.custom-motion-mode .editor-holder .contact-details-holder .input-holder {
  position: relative;
}
.custom-motion-mode .editor-holder .contact-details-holder .input-holder.popup-holder .popup {
  position: absolute;
  top: 50%;
  left: calc(100% + 2px);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  padding: 10px;
  width: 100%;
  max-width: 200px;
}
@media (max-width: 767px) {
  .custom-motion-mode .editor-holder .contact-details-holder .input-holder.popup-holder .popup {
    left: 0;
    max-width: 100%;
  }
}
.custom-motion-mode
  .editor-holder
  .contact-details-holder
  .input-holder.popup-holder
  .popup
  p:last-child {
  margin-bottom: 0;
}
.custom-motion-mode .editor-holder .contact-details-holder .input-holder .input-note {
  position: absolute;
  top: 50%;
  right: 12px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  color: rgba(0, 0, 0, 0.3);
  font-size: 12px;
  cursor: pointer;
  -webkit-transition: color 0.1s linear;
  -o-transition: color 0.1s linear;
  transition: color 0.1s linear;
}
.custom-motion-mode .editor-holder .contact-details-holder .input-holder .input-note:hover {
  color: #000;
}
.custom-motion-mode .editor-holder .editor-btn-holder .estimation-note {
  color: #e43030;
  font-size: 17px;
  font-weight: 700;
  line-height: 1.4;
  display: block;
  text-align: center;
}
.custom-motion-mode .editor-holder .editor-btn-holder .btn {
  min-width: 210px;
}
.custom-motion-mode .editor-holder .editor-btn-holder .btn.btn-fill,
.custom-motion-mode .editor-holder .editor-btn-holder .btn[type='button'].btn-fill,
.custom-motion-mode .editor-holder .editor-btn-holder .btn[type='submit'].btn-fill {
  background-color: #e43030;
  border-color: #e43030;
  color: #fff;
}
.custom-motion-mode .editor-holder .editor-btn-holder .btn.btn-fill:hover,
.custom-motion-mode .editor-holder .editor-btn-holder .btn[type='button'].btn-fill:hover,
.custom-motion-mode .editor-holder .editor-btn-holder .btn[type='submit'].btn-fill:hover {
  color: #e43030;
  background-color: #fff;
}
.custom-motion-mode .custom-file-input {
  color: #e43030;
}
.custom-motion-mode .custom-file-input.uploaded .file-input-note {
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: #e43030;
  color: #fff;
}
.custom-motion-list-section {
  padding: 38px 0 0;
}
.custom-motion-list-section .listing {
  padding-top: 67px;
}
.custom-motion-list-section .listing .list-item {
  width: 33.333%;
}
@media (max-width: 767px) {
  .custom-motion-list-section .listing .list-item {
    width: 50%;
  }
}
@media (max-width: 479px) {
  .custom-motion-list-section .listing .list-item {
    width: 100%;
  }
}
.custom-motion-list-section .card-preview:hover .list-item-descr .btn-holder {
  display: block;
}
.custom-motion-list-section .card-preview:hover .list-item-descr .btn {
  opacity: 1;
  visibility: visible;
}
.custom-motion-list-section .card-preview .img-holder {
  padding-top: 195%;
  margin-bottom: 16px;
}
.custom-motion-list-section .card-preview .list-item-descr .list-item-name-holder {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  min-height: 35px;
}
.custom-motion-list-section .card-preview .list-item-descr .list-item-name-holder h3 {
  margin-bottom: 0;
  margin-right: 10px;
}
.custom-motion-list-section .card-preview .list-item-descr .btn-holder {
  display: none;
  padding-left: 10px;
  -webkit-box-flex: 200;
  -ms-flex-positive: 200;
  flex-grow: 200;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  text-align: right;
}
.custom-motion-list-section .card-preview .list-item-descr .btn {
  padding: 7px 4px;
  min-width: 115px;
  font-size: 14px;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-weight: 900;
  text-transform: uppercase;
  -webkit-transition:
    opacity 0.1s linear,
    visibility 0.1s linear,
    color 0.1s linear,
    background 0.1s linear,
    border 0.1s linear;
  -o-transition:
    opacity 0.1s linear,
    visibility 0.1s linear,
    color 0.1s linear,
    background 0.1s linear,
    border 0.1s linear;
  transition:
    opacity 0.1s linear,
    visibility 0.1s linear,
    color 0.1s linear,
    background 0.1s linear,
    border 0.1s linear;
  opacity: 0;
  visibility: hidden;
}
.custom-motion-list-nav {
  position: relative;
  margin-bottom: 37px;
}
@media (max-width: 1199px) {
  .custom-motion-list-nav {
    padding: 0 20px;
  }
}
.custom-motion-list-nav:before {
  content: '';
  position: absolute;
  bottom: -37px;
  left: -52px;
  right: -52px;
  height: 1px;
  background-color: #191919;
}
.custom-motion-list-nav .slick-arrow {
  font-size: 0;
  border: 0;
  background: 0 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: 0;
  padding: 0;
  position: absolute;
  z-index: 2;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.custom-motion-list-nav .slick-arrow.slick-prev {
  left: -30px;
}
@media (max-width: 1199px) {
  .custom-motion-list-nav .slick-arrow.slick-prev {
    left: 0;
  }
}
.custom-motion-list-nav .slick-arrow.slick-next {
  right: -30px;
}
@media (max-width: 1199px) {
  .custom-motion-list-nav .slick-arrow.slick-next {
    right: 0;
  }
}
.custom-motion-list-nav .slick-arrow.slick-next:before {
  content: '\e906';
}
.custom-motion-list-nav .slick-arrow:before {
  content: '\e903';
  font-family: icomoon !important;
  font-size: 20px;
}
.custom-motion-list-nav .btn.btn-outline-secondary {
  border-color: #e43030;
  font-size: 14px;
  text-transform: uppercase;
  padding: 9px 18px;
  border-radius: 3em;
  font-family: 'Recoleta Alt', Arial, Helvetica, sans-serif;
  font-weight: 900;
  margin-right: 22px;
}
.custom-motion-list-nav .btn.btn-outline-secondary.active-item,
.custom-motion-list-nav .btn.btn-outline-secondary:hover {
  color: #fff;
  background-color: #e43030;
}
.files-upload-holder {
  max-width: 380px;
  margin: 0 auto 62px;
  text-align: center;
}
@media (max-width: 767px) {
  .files-upload-holder {
    margin-bottom: 30px;
  }
}
.files-upload-holder .files-upload-note {
  line-height: 1.4;
  margin-bottom: 20px;
}
.files-upload-holder .files-upload-note p:last-child {
  margin-bottom: 0;
}
.files-upload-holder .files-upload-block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 -15px;
  padding-top: 4px;
}
.files-upload-holder .files-upload-block .custom-file-input {
  width: calc(100% - 30px);
  margin: 0 15px 28px;
}
@media (min-width: 767px) {
  .files-upload-holder .files-upload-block .custom-file-input {
    width: calc(50% - 30px);
  }
}
.files-upload-holder .content-field {
  text-align: left;
}
.files-upload-holder .input-holder {
  position: relative;
}
.files-upload-holder .input-holder .keyboardInputInitiator {
  font-size: 0;
  position: absolute;
  top: 55%;
  right: 14px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  display: inline-block;
  vertical-align: middle;
  width: 23px;
  background-image: url(../images/icon-20.png);
  background-size: contain;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  -webkit-transition: opacity 0.4s linear;
  -o-transition: opacity 0.4s linear;
  transition: opacity 0.4s linear;
}
@media (max-width: 767px) {
  .files-upload-holder .input-holder .keyboardInputInitiator {
    display: none !important;
  }
}
.files-upload-holder .input-holder .keyboardInputInitiator:hover {
  opacity: 0.8;
}
.custom-file-input {
  position: relative;
  background-color: #fff;
  border-radius: 23px;
  padding: 21px 22px;
  overflow: hidden;
  font-size: 11px;
  color: #deb372;
}
.custom-file-input.uploaded {
  padding-bottom: 37px;
}
.custom-file-input.uploaded .file-input-title {
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 10px;
  font-weight: 400;
}
.custom-file-input.uploaded .file-input-note {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #deb372;
  padding: 10px 8px;
  color: #191919;
  text-decoration: underline;
  text-align: center;
  font-size: 0;
}
.custom-file-input.uploaded .file-input-note:before {
  content: 'Replace';
  font-size: 11px;
}
.custom-file-input.uploaded .img-holder {
  overflow: hidden;
  max-height: 100px;
  margin-bottom: 4px;
}
.custom-file-input.uploaded canvas {
  display: block;
}
.custom-file-input.uploaded canvas + img {
  display: none;
}
.custom-file-input [type='file'] {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  opacity: 0;
  cursor: pointer;
}
.custom-file-input .img-holder {
  min-height: 66px;
  margin-bottom: 7px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.custom-file-input canvas {
  display: none;
  width: 132px;
  height: 102px;
}
.custom-file-input img {
  display: block;
  margin: 0 auto;
}
.custom-file-input .file-input-title {
  color: #747474;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.35;
  text-transform: uppercase;
  margin-bottom: 1px;
}
.preview-video-block {
  position: relative;
}
.preview-video-block .custom-play-btn-holder {
  position: absolute;
  top: 49%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 51%;
  height: 85%;
  border-radius: 35px;
  overflow: hidden;
}
.preview-video-block .custom-play-btn-holder.video-playing video {
  opacity: 1;
  visibility: visible;
}
.preview-video-block .custom-play-btn-holder video {
  -webkit-transition:
    opacity 0.4s linear,
    visibility 0.4s linear;
  -o-transition:
    opacity 0.4s linear,
    visibility 0.4s linear;
  transition:
    opacity 0.4s linear,
    visibility 0.4s linear;
  opacity: 0;
  visibility: hidden;
  background-color: #000;
}
.preview-video-block .custom-play-btn-holder .over-block {
  background-color: transparent;
}
.preview-video-block .custom-play-btn-holder .over-block .video-play-btn {
  background-image: none;
  color: #fff;
  line-height: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 122px;
  height: 122px;
  padding: 0 0 0 10px;
  border-radius: 50%;
  border: 2px solid #fff;
  background-color: rgba(0, 0, 0, 0.5);
}
@media (max-width: 1023px) {
  .preview-video-block .custom-play-btn-holder .over-block .video-play-btn {
    width: 80px;
    height: 80px;
    padding: 2px 0 0 6px;
  }
}
.preview-video-block .custom-play-btn-holder .over-block .video-play-btn:before {
  content: '\f04b';
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  font-size: 44px;
}
@media (max-width: 1023px) {
  .preview-video-block .custom-play-btn-holder .over-block .video-play-btn:before {
    font-size: 28px;
  }
}
#buy_now.btn.btn-fill:disabled,
#simcha_buy_test.btn.btn-fill:disabled,
button[disabled] {
  border: 0 !important;
  background-color: #ccc !important;
  color: #666 !important;
  cursor: not-allowed;
}
@-webkit-keyframes floating {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(5%);
    transform: translateY(5%);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes floating {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(5%);
    transform: translateY(5%);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@-webkit-keyframes show100 {
  from {
    stroke-dashoffset: 157;
  }
  to {
    stroke-dashoffset: 0;
  }
}
@keyframes show100 {
  from {
    stroke-dashoffset: 157;
  }
  to {
    stroke-dashoffset: 0;
  }
}
