.upd_card-slider {

  div.upd_card-slide {
    transform: scale(1);
  }
}

.upd_card-slider-section {
  &.upd_card-slider-section {
    padding-bottom: 20px;
  }

  .section-heading {
    margin-bottom: 60px;
    @media (max-width: 767px) {
      margin-bottom: 33px;
    }
  }
}

.upd_card-slider {
  &.swiper {
    height: 100%;
    width: 100%;
    padding: 25% 0;
    max-width: 100%;
  }

  .swiper-wrapper {
    align-items: center;
  }
  .swiper-slide {
    height: 25.7vw;
    //transform: scale(1.9);
    transform-origin: 50% 50%;
    transition: transform 0.4s linear, transform-origin 0.4s linear, margin 0.4s linear;
    border: 0;
    outline: none;
    position: relative;
    text-align: center;
    @media screen and (min-width: 768px) {
      height: 24.5vw;
    }
    @media screen and (min-width: 1024px) {
      height: 195px;
    }

    @media screen and (min-width: 1024px) {
      padding: 0 30px;
    }

    .upd_card-slide {
      width: 100%;
      height: 100%;
    }

    .card-holder {
      display: inline-block;
      vertical-align: middle;
      box-shadow: 0px 6px 8px #00000087;
      //@media screen and (min-width: 1024px) {
      //  -webkit-box-shadow: 0px 10px 25px -2px rgba(0,0,0,0.45);
      //  -moz-box-shadow: 0px 10px 25px -2px rgba(0,0,0,0.45);
      //  box-shadow: 0px 10px 25px -2px rgba(0,0,0,0.45);
      //}
      width: 100%;
      height: 100%;
      > img {
        max-height: 250px;
        max-width: 100%;
        width: auto;
        height: auto;
        margin: 0 auto;
      }
    }

    &.swiper-slide-active {
      transform: scale(1.9);
      transform-origin: 0% 50%;
      @media screen and (min-width: 1024px) {
        padding: 0 20px;
      }

      .overlay-block {
        .btn {
          transform: scale(0.53);
        }
      }
    }

    &.swiper-slide-next {
      z-index: 2;
      transform: scale(2.1);
      transform-origin: 33% 50%;
      @media screen and (min-width: 1024px) {
        padding: 0 20px;
      }

      .overlay-block {
        .btn {
          transform: scale(0.48);
        }
      }

      & + .swiper-slide {
        z-index: 3;
        transform: scale(2.55);
        transform-origin: 50% 50%;
        @media screen and (min-width: 1024px) {
          padding: 0 20px;
        }

        .card-holder {
          &:hover {
            .overlay-block {
              opacity: 1;
              visibility: visible;
            }
          }
        }

        .overlay-block {
          .btn {
            transform: scale(0.39);
          }
        }

        & + .swiper-slide {
          z-index: 2;
          transform: scale(2.1);
          transform-origin: 67% 50%;
          @media screen and (min-width: 1024px) {
            padding: 0 20px;
          }

          .overlay-block {
            .btn {
              transform: scale(0.48);
            }
          }

          & + .swiper-slide {
            transform: scale(1.9);
            transform-origin: 100% 50%;
            @media screen and (min-width: 1024px) {
              padding: 0 20px;
            }

            .overlay-block {
              .btn {
                transform: scale(0.53);
              }
            }
          }
        }
      }
    }
  }
}

.upd_card-slider-wrapper {
  max-width: 1000px;
  margin: 0 auto;
  position: relative;
  @media screen and (min-width: 768px) {
    padding: 0 20px;
  }
  .swiper-button-prev,
  .swiper-button-next {
    cursor: pointer;
    font-size: 0;
    outline: 0;
    border: 0;
    background-color: transparent;
    display: block;
    width: 44px;
    height: 44px;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEgAAABGCAMAAABYOSIFAAAAflBMVEUAAABwcHBvb29wcHBvb29vb29wcHBwcHBwcHBwcHBwcHBvb29wcHBwcHBubm5sbGxvb29wcHBwcHBubm5wcHBwcHBvb29wcHBvb29vb29vb29vb29vb29tbW1vb29vb29wcHBvb29vb29wcHBwcHBvb29vb29ubm5xcXFwcHBB/fdKAAAAKXRSTlMA8NlA9w+fgOAwv1Fgbzga6tGQV0ogtrCphJhdTCklE6SiiGiW5EZ2CZzbebkAAAKqSURBVFjDvZjrdqIwEICHcEeiVEVLpdbLtpX3f8HdTJQokpkQz9n51yZ+zD2ZACWL9twkhQgCUSTNuV2Al8ybt24gb818KmV2rLpRqY6zCZiV6AgRK1dtepOyJJVhWAPUYSjTJOtNdNFqfcMUMh+u5bK4odYM5vJ+1aWMxzfE5VWv9wvF2Wt1gpLaJAOtVE2YFSAmjYGUOMV9VWvb0GjfIIZBaV+lo4tfG1xcgpMscfNm7KOp8Y6LlIFFpxI5OThLjqTVU2VFihPDBIkVKZoP4l6N6nPZszpViwc4FpdJfIP/IIsJ8+nejA/1n2c/H1QGEyB07P23avV3AlqG/ANFStQOk+KqwLKx1EKLjxQpu1f6UxtmJR04425B2qjCAPAjFSrBr6Wq9obgSQrx171Lt/aTBEmJnbTtA1ehld6k/N/qNxaHDpk/SeW3KpQT7vInYS6dAEBgcdCkDElEoQidRAGAC+mPZVWtfcLKxMyXtMW+JLHPeZJMb5WwZEA8CUFLLNgQXEjfNlKIhVu4gqBGUjoOKiAzec2S1N7oazS3BaimD26yth0QmEGBM2jW6cZuAQk0zVWfaG0pW+Hs7JmVo51tws/bhRxr+DEh/TkmIX8NiOP8ABAlUjoU7Yrk6KKl2wjPMW1ENzaeE5J2C4dWuzMcutXq5s9z2OYPqqhzmoP7iOOoYg/IM8vBmC2ZI9twmCN7zlwifgyHvkQw1xr8QkBzSkyiPoCWwP2ynDh7uPvVkSWX5GEPpCSD5nuyGMeJ7DAZjcSVrYvyDqrihyBGxABBXtijcJjBfiNEt3uKkN9QIy0T2IQxyz7dbacPfkdr7319FEVpJw3HQUuM60KP69JhXBd78mMb1weEDeeBGfek4f7QshPcI4vB8ChCxO4/PkS9/jT2+mPdX5TGlDBGX8hkAAAAAElFTkSuQmCC);
    right: -7%;
    background-size: contain;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    position: absolute;
    z-index: 10;
    top: 48%;
    transform: translateY(-50%);
    &:hover,
    &:focus {
      background-size: contain;
      background-position: 50% 50%;
      background-repeat: no-repeat;
    }

    &:before {
      content: none;
    }
  }
  .swiper-button-prev {
    right: auto;
    left: -7%;
    transform: translateY(-50%) rotate(180deg);
  }
}

.upd_card-slider-section {
  &.upd_card-slider-section {
    padding-bottom: 20px;
  }

  .section-heading {
    margin-bottom: 60px;
    @media (max-width: 767px) {
      margin-bottom: 33px;
    }
  }
}