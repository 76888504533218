div.best-seller-slider {
  @media screen and (max-width: 767px) {
    margin-right: -25px;
  }
}

.best-sellers-holder {
  .card-preview {
    border-radius: 5px;
    @media screen and (max-width: 1023px) {
      width: 200px;
    }
    @media screen and (max-width: 767px) {
      width: 134px;
    }

    .img-holder {
      padding-top: 0;
      padding-bottom: 129%;
      @media screen and (max-width: 767px) {
        height: auto;
      }
      img {
        display: block;
        border: 1.5px solid #deb372;
      }
    }
  }

  @media screen and (max-width: 767px) {
    margin-top: 15px;
    .block-heading {
      margin-bottom: 0;
      .heading {
        font-size: 19px;
        margin-bottom: 0;
      }
      &:before {
        left: calc(100% + 17px);
      }
    }
  }
}
