div.popup-slider {

  @media screen and (max-width: 767px) {
    margin-bottom: 14px;
  }
  .slick-slide {
    z-index: 2;

    &:not(.slick-active) {
      z-index: 1;
    }
  }

  .slick-prev,
  .slick-next {
    &:hover,
    &:focus {
      background-size: contain !important;
      background-repeat: no-repeat !important;
      background-image: url('./icon-06.png') !important;
    }

    &:before {
      content: none !important;
    }
  }

  .slick-next {
    &:hover,
    &:focus {
      background-image: url('./icon-07.png') !important;
    }
  }
}

.video-holder {
  .custom-play-btn-holder .over-block {
    @media screen and (max-width: 768px) {
     padding-bottom: 55px;
    }
    .video-play-btn {
      width: 61px;
      height: 61px;
      background-image: url("../../images/ico-play-video.svg");
      @media screen and (min-width: 992px){
        width: 101px;
        height: 101px;
      }
    }
  }
}

.popup-slider-descr {
  margin: 14px 0 10px;
  @media screen and (min-width: 768px) {
    margin: 45px 0 35px;
  }
  ul.descr-link-list {
    font-size: 13px;
    overflow-x: auto;
    flex-wrap: nowrap;
    @media screen and (min-width: 768px) {
      padding: 0 18px;
      min-height: 27px;
    }
    li {
      margin-right: 22px;
      padding-right: 0;
      flex-shrink: 0;
      white-space: nowrap;
      @media screen and (max-width: 768px) {
        padding: 2px 0 1px;
      }
      &:not(.active) {
        @media screen and (min-width: 768px) {
          padding-top: 5px;
        }
      }
    }
    li.active {
      padding-right: 26px;
      position: relative;
      @media screen and (min-width: 768px) {
        padding-right: 40px;
      }
      &:after {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
        width: 18px;
        height: 18px;
        background: url("../../images/Subtraction-2.svg") 50% 50% / contain no-repeat;
        @media screen and (min-width: 768px) {
          width: 27px;
          height: 27px;
        }
      }
      a {
        font-size: 17px;
        font-weight: bold;
        color: rgba(0,0,0,1);
        @media screen and (min-width: 768px) {
          font-size: 20px;
        }
      }
    }
  }
}

.upd-popup-holder div.upd-popup-inner {
  padding-top: 106px;
  .upd-popup {
    background-color: #f5f5f5;
  }
  .faq-popup-result {
    color: #deb372;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    border-radius: 11px;
    background-color: #f5f5f5;
    padding: 32px 32px 20px;
    max-width: 1052px;
    margin: 14px auto;
    @media screen and (min-width: 768px) {
      border-radius: 20px;
      margin-top: 20px;
    }
    .btn.wa-btn {
      @media screen and (max-width: 768px) {
        font-size: 13px;
        padding: 6px 14px;
        width: 164px;
        justify-content: flex-start;
      }
    }
    @media screen and (min-width: 768px) {
      font-size: 20px;
    }
    div {
      margin-bottom: 14px;
    }
  }
  @media screen and (max-width: 767px) {
    padding-left: 13px;
    padding-right: 13px;
    .upd-popup {
      border-radius: 11px;
      padding-top: 63px;
      .video-holder {
        padding-top: 132%;
      }
      .popup-slider .popup-slide {
        border-radius: 9px;
      }
      .popup-slider .slick-arrow {
        top: 53.5%;
      }
      .popup-slider-descr {
        margin-left: -10px;
        .descr-link-list {
          a {
            font-size: 9px;
          }
          li.active a {
            font-size: 12px;
          }
        }
      }
    }
    .upd-popup-section {
      padding-bottom: 16px;
      h2 {
        font-size: 19px;
      }
    }
  }
}

.upd-popup-holder {
  .upd-popup {
    .close-popup {
      background-image: url("../../images/video-close.svg");
      width: 18px;
      height: 18px;
      top: 22px;
      right: 30px;
    }
  }

  h2 {
    font-size: 20px;
    font-family: Roboto, Arial, Helvetica, sans-serif;
    font-weight: 700;
    text-transform: none;
    margin-bottom: 20px;
    text-align: center;
    @media screen and (min-width: 768px) {
      text-align: left;
      font-size: 30px;
    }
  }

  .btn {
    font-family: Roboto, Arial, Helvetica, sans-serif;
    font-weight: 700;
    min-width: 110px;
    padding: 10px 20px;
    border-radius: 25px;
    line-height: normal;
    border-color: #707070;
    font-size: 15px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    @media screen and (min-width: 768px) {
      font-size: 17px;
    }

    &:hover,
    &:active,
    &:visited {
      border-color: #e2b376;
    }

    i {
      margin-right: 13px;
      font-size: 22px;
      font-weight: 700;
      @media screen and (min-width: 768px) {
        font-size: 27px;
        margin-right: 20px;
      }
    }
  }

  .df {
    @media screen and (min-width: 768px) {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    & > div {
      margin-bottom: 13px;
      @media screen and (max-width: 768px) {
        padding: 0 40px;
      }
      @media screen and (min-width: 768px) {
        margin: 0 20px;
      }
    }

    & > .btn {
      margin: 0 10px;
      @media screen and (max-width: 768px) {
       font-size: 12px;
        border-radius: 33px;
        padding: 8px 14px;
        min-width: 77px;
      }
    }
  }

  .upd-popup-section {
    padding: 25px 0;
    position: relative;
    font-size: 16px;
    text-align: center;
    @media screen and (min-width: 768px) {
      padding: 45px 20px;
      font-size: 22px;
      text-align: left;
    }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      display: block;
      height: 1px;
      left: -20px;
      right: -20px;
      background-color: #808080;
      @media screen and (min-width: 768px) {
        left: -62px;
        right: -62px;
      }
    }
  }
}

.accordion {
  margin-bottom: 45px;
  @media screen and (max-width: 768px) {
    padding: 0 3px;
    margin-bottom: 0;
  }
}

.accordion-item {
  padding: 15px 20px;
  border-radius: 3px;
  border: 1px solid #b1b1b1;
  margin-bottom: 13px;
  text-align: left;
  @media screen and (min-width: 768px) {
    margin-bottom: 20px;
    padding: 25px;
    border-radius: 6px;
  }

  &.opened {
    .summary:after {
      transform: translateY(-50%) rotate(180deg);
    }

    .text {
      max-height: 3000px;
      padding-top: 18px;
      @media screen and (max-width: 768px) {
        font-size: 12px;
        line-height: 1.41;
      }
      @media screen and (min-width: 768px) {
        padding-top: 20px;
      }
    }
  }

  .summary {
    font-weight: 700;
    text-transform: uppercase;
    font-size: 13px;
    line-height: 1.38;
    color: #deb372;
    position: relative;
    padding-right: 40px;
    list-style: none;
    cursor: pointer;
    @media screen and (min-width: 768px) {
      font-size: 18px;
      line-height: 1;
    }

    &:after {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0;
      transition: transform .2s linear, border .2s linear;
      content: "\f078";
      font-family: "Font Awesome 5 Free";
      color: #808080;
      font-size: 16px;
      @media screen and (min-width: 768px) {
        font-size: 20px;
      }
    }
  }

  .text {
    overflow: hidden;
    max-height: 0;
    padding-top: 0;
    transition: 0.1s linear all;
    font-size: 14px;
    color: #191919;
  }
}
