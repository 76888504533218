.custom-motion-list-nav{
  &.free-status-list-nav-slider {
    .btn.btn-outline-secondary {
      border-color: #B88B48;
      &.active-item,
      &:hover {
        background-color: #B88B48;
      }
    }
  }
}

.free-status-list-section {
  .list-item {
    .img-holder {
      border: none;
      border-radius: 7px;
      overflow: hidden;
      .hint {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        text-align: center;
        padding: 30px;
        background-color: #191919;
        opacity: 0.8;
        color: #fff;
        z-index: 10;
        font: normal normal normal 19px/25px Roboto;
      }
      img {
        width: 100%;
      }
    }
    .btn {
      color: #fff;
      background-color: #B88B48;
      cursor: pointer;
      &:hover {
        background-color: #fff;
        color: #191919;
      }
    }
  }
}