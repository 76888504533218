.upd-pass-form {
  input {
    &[type=text], 
    &[type=password] {
      margin-bottom: 10px;
      height: 30px;
      padding-top: 7px;
      padding-bottom: 7px;
    }
  }
  

  .account-row {
    min-width: 240px;
    max-width: 100%;
  }
}