#footer .footer-top-block {
  @media screen and (max-width: 1023px) {
    background-image: url("../../images/path-84.svg");
    background-size: cover;
  }
}
@media (max-width: 767px) {
  #footer.upd_footer {
    .footer-top-block {
      padding-top: 53px;
      padding-bottom: 11px;
      .container {
        padding-left: 60px;
        padding-right: 60px;
      }
      .text-block {
        font-size: 14px;
        padding-bottom: 40px;
        padding-left: 0;
        padding-right: 0;
        .logo {
          max-width: 186px;
          margin-bottom: 11px;
        }
        .text-holder {
          text-align: left;
        }
      }
    }
    .footer-nav-upd {
      max-width: none;
      font-size: 14px;
      padding-top: 27px;
      &:before {
        width: 100%;
      }
      li {
        padding: 0;
      }
    }
    .footer-bottom-block {
      padding-top: 38px;
      padding-bottom: 34px;
      .network-upd-holder {
        strong {
          margin-right: 0;
        }
      }
      .network-upd {
        li {
          margin-right: 0;
          margin-left: 11px;
        }
      }
      .copyright-upd {
        color: #FFFFFF80;
        font-size: 12px;
      }
    }
  }
}
