mob-sub-nav.nav .menu-bottom > li {
  z-index: initial;
}

@media (min-width: 1024px) {
  .nav-holder {
    .menu-item-object-custom {
      .menu-open-close {
        .menu-open-close {
          li:first-child {
            display: none;
          }
        }
      }
    }
  }
}

@media (max-width: 1023px) {
  .ihtsy-app #header.header {
    padding-top: 14px;
    padding-bottom: 7px;
    border-bottom-width: 2px;
  }
  .menu-active .header .nav .menu-opener:after {
    top: 9px
  }
}

.header {
  z-index: 1000;

  .sub-nav-holder {
    .search {
      margin-left: 12px;
    }
  }

  .header-info {
    .nav {
      & > .search {
        margin-right: 12px;
        z-index: 1000;
        @media (min-width: 1024px) {
          display: none;
        }
      }
    }
    .video-nav-opener {
      img {
        margin-right: 4px;
      }
    }
  }

  .mob-sub-nav {
    @media screen and (max-width: 1023px) {
      display: block;
      border-top: 1px solid #fff;
      margin-top: 26px;
      padding-top: 23px;
    }

    li {
      margin-bottom: 11px;
    }

    a {
      align-items: center;
      display: flex;
      text-decoration: underline;
      text-transform: uppercase;
      font: normal bold 18px/24px Roboto;
    }
  }

  @media screen and (max-width: 1023px) {
    ul.main-nav-holder {
      & > .menu-item > .opener {
        font-size: 19px;
      }

      & > li.active > .opener {
        color: #fff !important;
      }
    }
    .menu-slide {
      .menu-item {
        .opener {
          font-size: 17px;
          font-family: "Recoleta Alt";
        }
      }

      .menu-open-close a:after {
        color: #B88B48;
      }

      .menu-slide {
        .opener {
          font-weight: 400;
          font-family: Roboto;
        }
      }
    }

    .main-menu-holder.menu {
      padding-right: 48px;
      padding-left: 48px;
      padding-top: 80px;

      ul.sub-nav li .far {
        font-size: 20px;
        margin-top: -4px;
      }

      .sub-nav-holder {
        padding-top: 0;
        margin-top: 35px;
        .search {
          display: none;
        }
        .sub-nav {
          border-top: 1px solid #fff;
          margin-top: 110px;
        }
        .btn + .sub-nav {
          margin-top: 30px;
        }
      }
    }
    .header-holder .logo {
      position: relative;
      z-index: 10000;
      max-width: 50px;
      margin-left: 6px;
    }
    div.nav {
      padding-left: 15px;

      .menu-opener:after {
        top: 16px;
      }
    }
    .mob-sub-nav {
      margin-right: 0;

      li {
        margin-right: 16px;
      }

      a {
        i {
          font-size: 20px;
        }
      }
    }
    a {
      font-size: 15px;
    }
  }

}

.header .main-nav-holder > li > .menu-slide,
.header-bottom .nav .menu-bottom > li > .slide,
.menu-open-close > li > .menu-slide,
.menu-open-close > li > .slide {
  opacity: 0;
  visibility: hidden;
  cursor: pointer;
  transition: opacity 0.4s linear, visibility 0.4s linear;
  z-index: 100;

  @media (min-width: 1024px) {
    display: block !important;
  }
}

.custom-motion-mode {
  .header-bottom {
    display: block !important;
    min-height: 0 !important;
    height: 0;
    overflow: hidden;
  }

  .sticky-block {
    display: none;
  }
}

.header-bottom .nav .menu-bottom {
  & > li.menu-item {
    flex-grow: 1;

    & > a {
      display: flex;
      align-items: center;
      line-height: normal;

      &:not(:only-child):after {
        transition: transform .2s linear, border .2s linear;
      }
    }

    &.active {
      & > a:after {
        transform: rotate(-180deg);
      }
    }
  }

  ul.inner-nav {
    a {
      transition: none;
    }
  }
}

.menu-open-close {
  & > li.menu-item {
    & > a:after {
      transition: transform .2s linear, border .2s linear;
    }

    a {
      transition: none;
    }

    &.active {
      & > a:after {
        @media (max-width: 1023px) {
          transform: translateY(-50%) rotate(270deg);
        }
      }
    }
  }
}

.header-bottom .nav .menu-bottom .inner-nav a,
.header .main-nav-holder li a {
  font-weight: 700;
}

.menu-open-close li .menu-slide,
.header-bottom .nav .menu-bottom > li.menu-item > .slide {
  @media (min-width: 1024px) {
    background-color: #e4e4e4;
    transition: none;
  }
}

.menu-open-close > li.menu-item {
  &.active,
  &:hover {
    & > a {
      @media (min-width: 1024px) {
        background-color: #cdcdcd;
      }
    }
  }
}

.header ul.main-nav-holder > li > .opener:not(:only-child):after {
  transition: transform .2s linear, border .2s linear;
  //display: none;
}

@media (max-width: 1023px) {

  .header ul.main-nav-holder > li > .opener:not(:only-child):after {
    content: '';
    width: 10px;
    height: 10px;
    border-bottom: 2px solid #fff;
    border-right: 2px solid #fff;
    transform: rotate(45deg) translateX(-50%);
  }

  .header .main-nav-holder > li.active > .opener:not(:only-child):after {
    transform: rotate(-135deg) translate(25%, -25%) !important;
  }
  .menu-item .menu-open-close a:after {
    content: '';
    width: 10px;
    height: 10px;
    border-bottom: 2px solid #B88B48;
    border-right: 2px solid #B88B48;
    transform: rotate(45deg) translateX(-50%);
    top: 35%;
  }

  .menu-item .menu-open-close > li.menu-item.active > a:after {
    transform: translate(-25%, 25%) rotate(225deg);
  }
}


.header-bottom .nav .menu-bottom .inner-nav li a:hover {
  @media (min-width: 1024px) {
    background-color: #cdcdcd;
  }
}

.header .header-info div.text-right {
  padding-right: 0;
  margin-bottom: 10px;
  @media (min-width: 1024px) {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  a {
    font-family: Roboto,Arial,Helvetica,sans-serif;
    font-size: 14px;
    line-height: 1.21;
    font-weight: 700;
    text-transform: uppercase;
    color: #191919;
    display: block;
    text-decoration: underline;
    transition: all .4s linear;
    &:hover {
      color: #deb372;
    }
    &:not(:last-child) {
      margin-right: 20px;
    }
  }

  .video-nav-opener {
    color: #191919;
    font-weight: 700;
    display: inline-flex;
    align-items: center;

    .far {
      margin-right: 4px;
      font-size: 18px;
    }
  }
}

.header .main-menu-holder {
  a.btn-header {
    font: normal normal bold 14px/19px Roboto;
    letter-spacing: 0px;
    text-transform: uppercase;
    min-width: 164px;
    height: 33px;
    padding: 7px 10px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 1023px) {
      width: 100%;
      display: block;
      margin: 0 auto;
      max-width: 400px;
    }
    & + .btn-header {
      margin-left: 8px;
      @media (max-width: 1023px) {
        margin-left: auto;
        margin-top: 8px;
      }
    }
  }
  a.btn-fill {
    color: #fff;
  }
}

.header .main-menu-holder ul.sub-nav {
  align-items: center;
  @media (max-width: 1023px) {
    display: flex;
    flex-direction: column;
    font-size: 16px;
  }

  .btn {
    min-width: 150px;
    padding: 7px 20px;
    font-weight: 700;
    color: #191919;
    max-width: 400px;
    margin: 0 auto;
    line-height: normal;
    @media (max-width: 1023px) {
      border-bottom: none;
      padding: 14px 20px;
      border-radius: 76px;
    }

    &:not(.btn-danger) {
      @media (max-width: 1023px) {
        border: 2px solid #B88B48;
        background-color: transparent;
        color: #fff;
        &:hover {
          background-color: #B88B48;
          color: #fff;
        }
      }
    }

    &:hover {
      color: #191919;
    }
  }

  .btn-danger {
    color: #fff;
  }

  .menu-item-button {
    @media (max-width: 1023px) {
      margin-bottom: 20px;
    }
  }

  li {
    @media (max-width: 1023px) {
      width: 100%;
    }

    &:last-child {
      @media (min-width: 1023px) {
        padding-left: 20px;
        margin-left: 15px;
        position: relative;
      }

      a {
        display: flex;
        align-items: center;
        @media (max-width: 1023px) {
          flex-direction: row-reverse;
          justify-content: space-between;
          padding-right: 0;
          font-size: 16px;
        }
      }

      &:before {
        @media (min-width: 1023px) {
          content: '';
          display: inline-block;
          height: 20px;
          width: 1px;
          background-color: #eee;
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }

    .far {
      color: #e2b376;
      display: inline-block;
      margin-right: 4px;
      font-size: 16px;
    }
  }
}

.header {
  .menu-bottom,
  .main-nav-holder {
    .menu-item {
      .icon {
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          width: 20px;
          height: 20px;
        }

        margin-right: 8px;
      }
    }
  }

  .main-nav-holder {
    .menu-item {
      &.active {
        & > a svg {
          path {
            fill: #B88B48;
            stroke: #B88B48;
          }
        }
      }

      a {
        display: flex;
        align-items: center;
        @media screen and (max-width: 768px) {
          padding-bottom: 3px;
        }
      }

      .icon {
        @media screen and (min-width: 768px) {
          display: none;
        }

        path {
          fill: #fff;
          stroke: #fff;
        }
      }
    }
  }
}


// .header {
//   .main-nav-holder {
//     > li {
//       > .opener {
//         @media (max-width: 1023px) {
//           display: inline-block;
//           position: static;
//         }
//       }
//     }
//   }
// }


.nav {
  .menu-item {
    .menu-slide {
      display: none;
      opacity: 0;
      visibility: hidden;
    }

    &.active {
      & > .menu-slide {
        display: block;
        opacity: 1;
        visibility: visible;
      }
    }
  }
}

.header-bottom .nav .menu-bottom > .menu-item.active > .slide {
  display: block;
  opacity: 1;
  visibility: visible;
}
