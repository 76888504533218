.download-popup.upd-popup-holder {
  .upd-popup-inner div.upd-popup {
    padding-top: 24px;
    h4 {
      margin-bottom: 0;
      color: #191919;
    }
    .form-control {
      border-radius: 24px;
      font-size: 18px;
      padding: 12px 20px;
      height: 47px;
    }
    .input-block {
      margin-bottom: 20px;
    }
    .whatsapp-block {
      padding: 15px 30px 20px;
      background-color: #DEB372;
      margin: 0 -20px 36px;
      @media screen and (min-width: 768px){
        margin: 0 -60px 36px;
      }
      .content-details {
        display: flex;
        align-items: center;
        justify-content: center;
        @media screen and (max-width: 767px) {
          margin-bottom: 0;
        }
        label {
          font-size: 18px;
          font-weight: 500;
          margin-bottom: 15px;
          text-align: left;
        }
        .styled-checkbox + label:before {
          border-radius: 50%;
        }
        .styled-checkbox:checked + label:before {
          background-color: #fff;
        }
        .styled-checkbox:checked + label:after {
          content: "\f058";
          font-size: 21px;
          position: absolute;
          width: auto;
          height: auto;
          box-shadow: none;
          top: -2px;
          left: 0px;
          font-family: "Font Awesome 5 Free";
          transform: none;
          background: transparent;
          color: #191919;
          font-weight: 900;
        }
      }
      .content-field[type="text"] {
        height: 47px;
        padding: 10px 20px 10px 110px;
        background-color: #FFFFFF;
        border: 1px solid #191919;
        font-weight: normal;
        border-radius: 24px;
        text-align: left;
        color: #191919;
        font-size: 18px;
        width: 100%;
        &:disabled {
          opacity: 0.4;
        }
      }
      .whatsapp-number {
        position: relative;
        max-width: 374px;
        margin: 0 auto;
        &.disabled {
          opacity: 0.4;
        }
        .select {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 20px;
          font-size: 14px;
          line-height: 1.35;
          color: #B88B48;
          background-color: transparent;
          text-align: left;
          z-index: 100;
          width: 80px;
        }
      }
    }
    .btn-block {
      margin-top: 35px;
    }
    .total-layout {
      margin-bottom: 20px;
      @media screen and (min-width: 768px){
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      &.disabled {
        opacity: 0.4;
        .btn {
          pointer-events: none;
        }
      }
      .total {
        font-weight: 500;
        font-size: 28px;
        line-height: 1.18;
        @media screen and (max-width: 767px){
          margin-bottom: 10px;
          font-size: 20px;
        }
      }
      .btn {
        border-radius: 3em;
        font-size: 18px;
        font-weight: 700;
        padding: 11px 7px;
        min-width: 193px;
        font-family: Roboto,Arial,Helvetica,sans-serif;
        i {
          font-size: inherit;
          margin: 0;
        }
      }
    }
  }
}
