.login-form {
  .form-row {
    margin-bottom: 31px;
  }
}

.login-form {
  .error {
    font-size: 12px;
    color: #f00;

    &.error-result {
      font-size: 20px;
    }
  }

  .login-submit {
    text-align: right;
    
    input[type=submit] {
      display: inline-block;
      vertical-align: middle;
    }
  }
}