.react-draggable {
  border: 2px dashed #DEB372;
  z-index: 100;
  .dot {
    width: 10px;
    height: 10px;
    border: 1px solid #deb372;
    background-color: #deb372;
    border-radius: 2px;
    position: absolute;
  }
  .dot-top-left {
    top: 0;
    left: 0;
    transform: translate(-50%, -50%);
  }
  .dot-top-right {
    top: 0;
    right: 0;
    transform: translate(50%, -50%);
  }
  .dot-bottom-left {
    bottom: 0;
    left: 0;
    transform: translate(-50%, 50%);
  }
  .dot-bottom-right {
    bottom: 0;
    right: 0;
    transform: translate(50%, 50%);
  }
  img {
    width: 100%;
  }
}
