.custom-dropdown {
  &.custom-dropdown-active {
    .custom-dropdown-drop {
      opacity: 1;
      visibility: visible;
      display: block;
    }
  }

  .custom-dropdown-drop {
    opacity: 0;
    visibility: hidden;
    display: block;
    transition: opacity 0.4s linear, visibility 0.4s linear, top 0.4s linear !important;
  }
}

.list-item a.card-preview {
  padding: 5px 5px 16px;
  border-radius: 5px;
  overflow: initial;
  position: relative;
  .card-preview-tags {
    position: absolute;
    display: flex;
    grid-gap: 10px;
    top: -13px;
    left: 10px;
    z-index: 1;
    .btn {
      font: normal bold 11px/19px Roboto;
      letter-spacing: 0px;
      min-width: 100px;
      padding: 4px 10px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      white-space: nowrap;
      pointer-events: none;
      @media (max-width: 1023px) {
        font-size: 10px;
        min-width: 80px;
      }
      @media (max-width: 475px) {
        font-size: 14px;
        min-width: 130px;
      }
    }
  }
  .img-holder {
    overflow: hidden;
    .lazyload-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      img {
        display: block;
        border: 1.5px solid #deb372;
      }
    }
  }
}

.category-heading {
  .category-title-holder {
    h1 {
      @media screen and (max-width: 767px) {
        font-size: 18px;
        margin-bottom: 19px;
        max-width: 50%;
      }
      &:after {
        display: none;
      }
    }

    &.single-category {
      &:after {
        content: none;
      }
    }
  }
}


.category-title-holder {
  min-width: 50%;

  .subcategory-select-holder {

    .custom-dropdown.custom-dropdown-active {
      .custom-dropdown-drop {
        li:hover {
          background-color: #e2b376;
        }
      }
    }
  }
}

.category-tags-holder {
  min-width: 50%;

  @media (min-width: 1024px) {
    width: 50%;
  }
}

.listing-holder {
  .listing {
    &.third-width {
      .list-item {
        @media (min-width: 1199px) {
          width: 33.333%;
        }
      }
    }

    &.full-width {
      .list-item {
        width: 100%;
      }
    }
  }
}

@media (max-width: 414px) {
  .list-item {
    .card-preview {
      padding: 0;
      border-radius: 0;
    }
  }
}

@media (max-width: 564px) {
  .category-filters ul.filters-list {
    li {
      padding: 0 6px;
    }
  }
}

@media (max-width: 768px) {
  section.listing-section {
    padding-top: 26px;

    .category-heading h1 {
      margin-right: 24px;

      &:after {
        margin-left: 24px;
      }
    }
  }
  .card-preview {
    span.img-holder {
      margin-bottom: 4px;
      height: 197px;
    }
    .list-item-descr {
      flex-wrap: nowrap;
      padding: 0;
      .list-item-name-holder {
        h3 {
          font-size: 12px;
        }
        strong {
          font-size: 8px;
          margin: 0;
        }
      }
      span.price {
        font-size: 15px;
        margin-left: 5px;
        margin-right: 9px;
      }
    }
  }
}

div.category-filters {
  .filters-list {
    margin-bottom: -6px;
  }
  @media screen and (max-width: 767px) {
    border-top: 1px solid #707070;
    border-bottom: 1px solid #707070;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 17px;
    width: calc(100% + 50px);
    margin-right: -25px;
    margin-left: -25px;
    padding: 12px 25px;
    .filter-title {
      font-size: 16px;
      margin-right: 30px;
    }
    .filters-list {
      margin: 0 -3px -1px;
      flex-grow: 1;
      justify-content: space-around;

      li {
        &:last-child {
          .custom-dropdown.custom-dropdown-active .custom-dropdown-drop {
            left: auto;
            right: 0;
          }
        }
      }
    }
    .custom-dropdown {
      font-size: 14px;
    }
  }
}

@media screen and (max-width: 767px) {
  .category-heading {
    position: relative;
  }
  div.category-title-holder {
    order: 1;
    margin-bottom: 26px;
    display: block;
    width: 100%;
  }
  .category-filters {
    order: 2;
  }
  div.category-view-holder {
    position: absolute;
    right: 0;
    top: -7px;

    .category-view-title {
      font-size: 16px;
      margin-right: 8px;
    }

    .category-view-list {
      a {
        border-radius: 3px;
        padding-top: 6px;
        padding-bottom: 6px;
      }

      .full-width-view {
        margin-right: 7px;

        img {
          width: 17px;
        }
      }
    }
  }
  div.category-tags-holder {
    order: 4;
    overflow-x: auto;
    padding: 13px 0;
    margin: 0 -25px 11px;

    ul {
      flex-wrap: nowrap;
    }

    .btn.btn-outline-secondary {
      white-space: nowrap;
      margin: 0 5px;
    }
  }
}

@media screen and (max-width: 364px) {
  div.category-tags-holder {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.categories-list {
  .category-item {
    .img-holder {
      .lazyload-wrapper {
        max-width: 100%;
        max-height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          max-width: 60px;
          @media screen and (min-width: 1024px) {
            max-width: 100px;
          }
        }
      }
    }
  }
}

.card-preview {
  .list-item-descr {
    h3 {
      font-size: 18px;
    }

    .price {
      margin-right: 0;
      font-size: 20px;
    }

    .list-item-name-holder strong {
      margin-left: 0;
    }
  }
}

@media (max-width: 767px) {
  div.categories-list {
    .category-item {
      border-radius: 10px;
      margin-bottom: 22px;

      .img-holder {
        margin-bottom: 14px;
      }

      .category-item-title {
        font-size: 14px;
      }
    }
  }
}

.category-view-holder {
  @media screen and (max-width: 767px) {
    .third-width-view,
    .fourth-width-view {
      display: none;
    }
  }
  @media screen and (min-width: 767px) {
    .two-width-view {
      display: none;
    }
  }
}

.listing-holder .listing.two-width .list-item {
  width: 50%;
  @media screen and (max-width: 767px) {
    padding-right: 3px;
    padding-left: 3px;
    .card-preview {
      .card-preview-tags {
        top: -3px;
        .btn {
          font-size: 8px;
          min-width: auto;
          padding: 3px 5px;
          line-height: 1;
        }
      }
    }
  }
}

.category-view-holder {
  @media screen and (min-width: 767px) {
    padding-left: 21px;
    position: relative;
    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-40%);
      width: 1px;
      height: 14px;
      background-color: #DEB372;
    }
  }
}