.contact-form {
  .error {
    font-size: 12px;
    color: #f00;
  }

  .form-block {
    align-items: flex-start;
  }

  .btn-holder {
    text-align: right;

    input[type=submit] {
      display: inline-block;
      vertical-align: middle;
    }
  }
}