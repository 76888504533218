.custom-file-input {
  &.uploaded {
    .img-holder {
      align-items: flex-start;

      img {
        display: none;
      }
    }
  }

  .react-pdf__Page__textContent,
  .react-pdf__Page__annotations {
    display: none;
  }

  canvas {
    width: 132px !important;
    height: auto !important;
  }
}

.custom-motion-mode {
  .editor-holder {
    transition: background .3s linear;

    &.thanks {
      background-size: cover;
    }
  }
}