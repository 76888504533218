@media (max-width: 767px) {
  section.contact-details-section {
    padding-top: 15px;
    padding-bottom: 12px;
    .contact-details-block {
      text-align: left;
      .container {
        padding-left: 65px;
        padding-right: 65px;
      }
      .heading-note {
        max-width: none;
        font-size: 14px;
        padding-left: 5px;
      }
      .section-heading {
        padding: 0;
        text-align: left;
        margin-bottom: 26px;
      }
      .section-title {
        margin-bottom: 24px;
      }
      .contact-details-list li {
        text-align: left;
        padding: 11px 0;
        font-size: 14px;
        &:before {
          left: 0;
          transform: none;
        }
        a {
          display: flex;
          align-items: center;
          i {
            font-size: 1.5em;
            margin-right: 10px;
            &.fa-phone-alt {
              font-size: 1.1em;
            }
          }
          img {
            margin-right: 6px;
            width: 20px;
            max-width: 20px;
          }
        }
      }
    }
  }
}