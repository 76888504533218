.thanks-popup {
  text-align: center;
  &.upd-popup-holder .upd-popup-inner {
    .upd-popup {
      @media screen and (min-width: 768px) {
        padding: 40px 52px;
      }
    }
  }
  .icon {
    color: #B88B48;
    margin-bottom: 20px;
    @media screen and (max-width: 768px) {
      margin-bottom: 0;
    }
    svg {
      @media screen and (max-width: 768px) {
        width: 50px;
        height: 50px;
      }
    }
  }

  h4 {
    font-size: 33px;
    line-height: 1.16;
    font-weight: 500;
    letter-spacing: 0;
    color: #B88B48;
    margin-bottom: 10px;
    @media screen and (min-width: 768px) {
      font-size: 50px;
      line-height: 1;
      margin-bottom: 32px;
    }
  }

  .text-wrapper {
    line-height: 1.35;
    font-size: 14px;
    @media screen and (min-width: 768px) {
      font-size: 18px;
    }
    & > p {
      @media screen and (max-width: 768px) {
        padding: 0 35px;
      }
    }
  }

  .rate-block {
    border: 1px solid #B88B48;
    border-radius: 20px;
    padding: 20px;
    margin-top: 20px;
    margin-bottom: 32px;
    p {
      font-weight: 500;
      font-size: 24px;
      line-height: 1.16;
      @media screen and (min-width: 768px) {
        font-size: 19px;
        line-height: 1.3;
      }
    }
    .btn {
      border-color: #e2b376;
    }
  }

  .email {
    font-size: 14px;
    span {
      font-size: 18px;
    }
  }
}