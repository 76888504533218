.error-block {
  padding: 60px 0;
  margin: 20px 0;
  text-align: center;
  border: 1px solid red;
  border-width: 2px 0;
  font-size: 18px;

  h2 {
    font-size: 40px;
    margin-bottom: 20px;
  }

  p {
    &:last-child {
      margin-bottom: 0;
    }
  }
}