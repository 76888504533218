.details-block {
  .content-details {
    position: relative;

    &:not(.show-hiddens) {
      .hidden-field {
        display: none;
      }
    }
  }

  .image-holder {
    > .popup-opener {
      background-color: #deb372;
      border-radius: 3em;
      padding: 10px 32px;
      font-size: 20px;
      text-transform: uppercase;
      transition: background 0.4s linear;
      font-weight: 500;

      &:hover {
        background-color: rgba(222, 179, 114, 0.8);
      }
    }
  }
}

.input-keyboard {
  position: static;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.4s linear, visibility 0.4s linear;

  .input-keyboard-block {
    position: absolute;
    z-index: 2;
    top: 100%;
    left: 0;
    width: 100%;
  }
}

.popup-holder {
  .popup {
    z-index: 100;
  }
}

.preview-block {
  svg {
    width: 100%;
  }
}

.editor-holder .audio-list li label {
  max-width: 170px;

  .title {
    display: block;
    max-width: 100%;
    white-space: nowrap;
    // text-overflow: ellipsis;
    // overflow: hidden;
  }
}

.editor-holder .content-details div.input-holder .reset-link {
  font-weight: 700;
  color: #caa876;
}


.editor-holder div.editor-music-holder {
  margin-bottom: 0;
}

.image-upload-holder {
  display: flex;
  .image-holder:first-child {
    @media screen and (max-width: 1023px){
      padding-right: 8px;
    }
  }
}

.styled-checkbox {
  position: absolute; // take it out of document flow
  opacity: 0; // hide it

  & + label {
    position: relative;
    cursor: pointer;
    padding: 0;
  }

  // Box.
  & + label:before {
    content: '';
    margin-right: 10px;
    display: inline-block;
    vertical-align: text-top;
    width: 20px;
    height: 20px;
    background: white;
    border: 1px solid black;
  }

  // Box checked
  &:checked + label:before {
    background: #deb372;
  }

  // Disabled state label.
  &:disabled + label {
    color: #b8b8b8;
    cursor: auto;
  }

  // Disabled box.
  &:disabled + label:before {
    box-shadow: none;
    background: #ddd;
  }

  // Checkmark. Could be replaced with an image
  &:checked + label:after {
    content: '';
    position: absolute;
    left: 5px;
    top: 9px;
    background: black;
    width: 2px;
    height: 2px;
    box-shadow: 2px 0 0 black,
    4px 0 0 black,
    4px -2px 0 black,
    4px -4px 0 black,
    4px -6px 0 black,
    4px -8px 0 black;
    transform: rotate(45deg);
  }
}

@media (max-width: 768px) {
  div.editor-holder {
    padding-top: 28px;
    padding-bottom: 25px;

    .editor-card-note {
      font-size: 11px;
      padding-top: 13px;
    }

    .editor-note-holder {
      padding-top: 10px;
      margin-left: -10px;
      margin-right: -10px;
      margin-bottom: 10px;

      & > strong {
        font-size: 19px;
      }
    }

    .editor-music-holder {
      padding: 0;
      align-items: flex-start;

      .hidden-fields-holder {
        max-width: 100%;
        padding-right: 0;
        padding-left: 26px;

        & > label {
          font-size: 14px;
        }

        .fake-checkbox {
          right: 0;
          left: -26px;
          margin-left: 0;
          margin-right: 8px;
        }
      }
    }

    .content-details {
      max-height: none;
      overflow-y: initial;

      .content-field[type=text],
      .content-promo[type=text] {
        height: 43px;
      }
      .btn-holder {
        padding-top: 3px;
      }
      .editor-btn-holder {
        padding-right: 0;
        align-items: flex-end;
        .price {
          margin-bottom: 24px;
          margin-right: 20px;
        }
        .btn {
          margin-bottom: 7px;
        }
        .draft-link {
          font-size: 15px;
        }
      }
    }

    .editor-music-holder + .content-details {
      border: 1px solid #B88B48;
      border-radius: 27px;
      margin-right: -10px;
      margin-left: -10px;
      padding: 10px 10px 0;
      margin-bottom: 20px;
    }

    .music-holder {
      position: absolute;
      .popup-block {
        left: 0;
        transform: none;
      }
      .current-song {
        padding-top: 0;
        .song-title {
          display: inline-block;
          margin-right: 20px;
        }
      }
      & > label {
        font-size: 14px;
        padding: 11px 38px;
        .fake-checkbox {
          width: 16px;
          height: 16px;
        }
      }
    }
  }
}