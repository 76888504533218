.form-row {
  margin-bottom: 31px;
}

.register-form {
  .error {
    font-size: 12px;
    color: #f00;
  }

  .btn-block {
    text-align: right;
    
    [type=submit] {
      display: inline-block;
      vertical-align: middle;
    }
  }
}