.confirm-popup {
  &.upd-popup-holder {
    .upd-popup {
      .info-block {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;
        @media screen and (min-width: 768px) {
          margin-bottom: 25px;
          padding-right: 0;
          margin-bottom: 15px;
        }
      }

      .icon {
        width: 50px;
        height: 50px;
        flex-shrink: 0;
        margin-right: 23px;
        @media screen and (min-width: 768px) {
          width: 72px;
          height: 72px;
          margin-right: 30px;
        }

        svg {
          width: 100%;
          height: 100%;
        }
      }

      h4 {
        font-size: 33px;
        line-height: 1.16;
        font-weight: 500;
        letter-spacing: 0px;
        color: #B88B48;
        margin-bottom: 0;
        @media screen and (min-width: 768px) {
          font-size: 50px;
        }
      }

      p {
        max-width: 200px;
        margin: 0 auto 26px;
        @media screen and (min-width: 768px) {
          max-width: 310px;
          margin-bottom: 48px;
        }
      }

      .form-row {
        margin: 0 -12px 20px -12px;
      }

      .btn {
        border-radius: 48px;
        padding: 13px 27px;
        font-weight: 700;
        font-size: 15px;
        margin: 0 6px;
        @media screen and (min-width: 768px) {
          font-size: 18px;
          margin: 0 12px;
          padding: 10px 24px;
        }

        i {
          font-size: 16px;
          margin-right: 10px;
          @media screen and (min-width: 768px) {
            margin-right: 20px;
          }
        }

        &.btn-back {
          background-color: #191919;
          color: #fff;

          &:hover {
            background-color: #fff;
            color: #191919;
            border-color: #191919;
          }
        }

        &.btn-submit {
          @media screen and (min-width: 768px) {
            padding-left: 40px;
            padding-right: 40px;
          }

          i {
            margin-right: 0;
            margin-left: 10px;
          }
        }
      }
    }
  }
}

.buying-popup,
.confirm-popup,
.draft-popup,
.thanks-popup {
  &.upd-popup-holder {
    .upd-popup-inner {
      @media screen and (max-width: 768px) {
        padding-right: 25px;
        padding-left: 25px;
        padding-bottom: 5px;
      }

      .upd-popup {
        border: 1px solid #DEB372;
        border-radius: 10px;
        max-width: 538px;
        padding: 33px 25px 10px;
        font-size: 14px;
        line-height: 1.2;
        font-weight: 500;
        @media screen and (min-width: 768px) {
          padding: 92px 92px 69px;
          font-size: 20px;
          border-radius: 20px;
        }
      }
    }
  }
}

.draft-popup.upd-popup-holder {
  h4 {
    font-weight: 500;
    font-size: 30px;
    line-height: 1.175;
    margin-bottom: 20px;
    font-family: Roboto, Arial, Helvetica, sans-serif;
    @media screen and (min-width: 768px) {
      font-size: 40px;
      margin-bottom: 35px;
    }
  }
  .form-row {
    margin-top: 40px;
    display: block;
    margin-bottom: 0;
    .form-control {
      border: 1px solid #B88B48;
      border-radius: 24px;
      padding: 12px 14px;
      background-color: #fff;
    }
    .btn {
      margin-left: 0;
      margin-top: 20px;
      border-radius: 3em;
      font-size: 18px;
      font-weight: 700;
      padding: 11px 7px;
      line-height: 1;
      width: 180px;
    }
  }
}

.buying-popup.upd-popup-holder {
  .upd-popup-inner {
    .upd-popup {
      padding: 25px 15px 20px;
      @media screen and (min-width: 768px) {
        padding: 42px 52px 23px;
      }
    }
  }

  h4 {
    font-weight: 500;
    font-size: 30px;
    line-height: 1.175;
    margin-bottom: 20px;
    font-family: Roboto, Arial, Helvetica, sans-serif;
    @media screen and (min-width: 768px) {
      font-size: 40px;
      margin-bottom: 35px;
    }

    &:before {
      display: none;
    }
  }

  .form-control {
    border-radius: 24px;
    font-size: 14px;
    padding: 12px;
    height: 47px;
    @media screen and (min-width: 768px) {
      padding-left: 20px;
      padding-right: 20px;
      font-size: 18px;
    }
  }

  .input-block {
    margin-bottom: 12px;
    @media screen and (min-width: 768px) {
      margin-bottom: 17px;
    }
  }

  .btn-block {
    margin-top: 38px;
    @media screen and (max-width: 767px) {
      margin-top: 34px;
      text-align: right;
      padding-right: 11px;
    }
  }

  form > .total {
  @media screen and (max-width: 767px) {
    display: none;
  }
}

  .total {
    font-weight: 500;
    font-size: 20px;
    line-height: 1.18;
    @media screen and (min-width: 767px) {
      font-size: 28px;
      margin-left: auto;
      margin-right: 20px;
      margin-top: 0;
      width: auto;
    }
  }

  .total-layout {
    margin-bottom: 20px;
    @media screen and (max-width: 767px) {
      display: flex;
      align-items: center;
      padding-left: 12px;
    }

    .total {
      @media screen and (min-width: 767px) {
        display: none;
      }
    }

    &.disabled {

      .btn {
        pointer-events: none;
        opacity: 0.4;
      }
    }

    .btn {
      border-radius: 3em;
      font-size: 18px;
      font-weight: 700;
      padding: 11px 7px;
      min-width: 193px;
      font-family: Roboto, Arial, Helvetica, sans-serif;
      width: 309px;
      max-width: 100%;
      @media screen and (max-width: 767px) {
        width: 163px;
        min-width: 163px;
        margin-left: auto;
        font-size: 16px;
      }

      i {
        font-size: inherit;
        margin-left: 16px;
        margin-right: 0;
      }
    }
  }

  .card {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    align-items: flex-end;
    position: relative;

    .card-element {
      flex-shrink: 0;
    }

    .card-number {
      flex-grow: 1;
      border: 1px solid #B88B48;
      border-radius: 24px;
      padding: 12px 14px;
      position: relative;
      flex-basis: 100%;
      margin-bottom: 18px;
      @media screen and (min-width: 767px) {
        padding: 10px 20px;
        margin-bottom: 14px;
      }

      i {
        display: none;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 10px;
        color: #68B737;
      }

      .StripeElement--complete {
        & + i {
          display: block;
        }
      }
    }

    .card-expiry,
    .card-cvc {
      border-bottom: 1px solid #B88B48;
      flex-basis: 115px;
      margin: 0 14px;
      padding: 10px 0;
      text-align: center;
    }

    .card-cvc {
      flex-basis: 78px;
    }

    .total {
      @media screen and (max-width: 767px) {
        display: none;
      }
    }
  }

  .error {
    padding-top: 38px;
    color: red;
    font-size: 16px;
  }

  .loading {
    .preloader-holder {
      padding: 0;
      max-width: 100px;
      margin: 0 auto;

      img {
        width: 100%;
        opacity: 0.5;
      }
    }

    p {
      font-weight: 500;
      text-align: center;
      line-height: 1.2;
      font-size: 14px;
    }

    .title {
      color: #B88B48;
      margin-bottom: 5px;
    }
  }
}

