.error {
  color: #e43030;
  text-align: center;
  font-size: 11px;

  &:before {
    content: '';
    display: inline-block;
    vertical-align: baseline;
    width: 10px;
    height: 10px;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKBAMAAAB/HNKOAAAAHlBMVEUAAADkLi7mLy/kLy/lLS3mMDDpLS3rJyfrKSnjLi5+IpTeAAAACnRSTlMAkTa5PFAWGhluRmPdIwAAADlJREFUCNdjYHA0FmFgYBFjSHRgcGRgYBBiEGMIYmhkMACSzBASLFIIlJ0ElGWRYCh0YGBQNBZiAAC7MgZ7u/41+AAAAABJRU5ErkJggg==);
    background-position: 50% 50%;
    background-size: contain;
    background-repeat: no-repeat;
  }
}

.image-holder {
  .croppie-msg {
    margin-top: 10px;
  }
}

@media screen and (max-width: 767px) {
  .image-holder .btn-holder .btn:nth-child(2n) {
    margin-bottom: 0;
  }
}

.details-block .image-holder {
  padding-bottom: 13px;
  @media screen and (max-width: 767px) {
    padding-top: 13px;
  }
  .popup-opener.btn-add {
    border: 1px solid #DEB372;
    border-radius: 23px;
    font: 600 18px/1 Roboto;
    color: #191919;
    text-transform: uppercase;
    background: transparent;
    display: flex;
    align-items: center;
    height: 45px;
    padding: 10px 25px;
    transition: background 0.4s linear;
    &:hover {
      background-color: #DEB372;
    }
    .icon-pencil {
      margin-right: 5px;
      width: 22px;
      height: 22px;
    }
    .icon-upload {
      width: 11px;
      height: 14px;
      margin-right: 9px;
    }
  }
}

.image-upload-popup {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  align-items: center;
  justify-content: center;
  background-color: rgba(0,0,0,0.6);
  z-index: 1000;
  font-family: Roboto;
  display: none;
  overflow: auto;


  &.active {
    display: flex;
  }
  &.second {
    .popup-content {
      width: 536px;
    }
  }
  .popup-content {
    background: #FFFFFF;
    border: 1px solid #DEB372;
    border-radius: 20px;
    padding: 20px;
    width: 80%;
    position: relative;
    max-width: calc(100vw - 22px);
    max-height: 100vh;
    @media screen and (min-width: 768px){
      padding: 50px;
      max-width: 1136px;
    }
    .popup-close {
      position: absolute;
      top: 15px;
      right: 15px;
      cursor: pointer;
      @media screen and (min-width: 768px){
        top: 45px;
        right: 45px;
      }
    }
    h2 {
      font: 600 25px/29px Roboto;
      margin: 0 0 18px;
    }
    p {
      font-size: 14px;
      @media screen and (min-width: 768px){
        font-size: 18px;
      }
      a {
        color: #B88B48;
        text-decoration: underline;
      }
    }
    .upload-area {
      background-color: #F5F5F5;
      border: 1px solid #B9B9B9;
      border-radius: 20px;
      height: 265px;
      display: flex;
      align-items: center;
      justify-content: center;
      @media screen and (min-width: 768px){
        height: 465px;
      }
      .btn-add {
        svg {
          margin-right: 0;
          margin-left: 5px;
        }
      }
    }
  }
  .croppie-holder {
    .croppie-msg {
      margin: 25px 0;
      border-radius: 15px;
      overflow: hidden;
      padding: 0;
      min-height: 350px;
      @media screen and (min-width: 768px){
        min-height: 450px;
      }
    }
    .jcf-file {
      &:hover {
        .jcf-upload-button {
          background-color: transparent;
          text-decoration: none;
        }
      }
      .jcf-upload-button {
        border: none;
        font: 400 18px/24px Roboto;
        color: #B88B48;
        text-decoration: underline;
        &:hover {
          background-color: transparent;
          text-decoration: none;
        }
      }
    }
    .apply.btn {
      border: 1px solid #DEB372;
      border-radius: 23px;
      font: 600 18px/1 Roboto;
      color: #191919;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 45px;
      padding: 10px 32px;
      background-color: #DEB372;
      margin-left: auto;
      &:hover {
        background-color: transparent;
        transition: background 0.4s linear;
      }
    }
  }

}

.btn-add-remove {
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  margin-left: 10px;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
    border-radius: 50%;
  }
  svg {
    width: 12px !important;
    height: 12px !important;
    margin-right: 0 !important;
    position: relative;
    z-index: 1;
  }
}