div.mobile-preview-block {
  z-index: 10000;
  opacity: 1;
  transition: opacity 0.5s ease, transform 0.1s ease 0.5s;
  &.hide {
    opacity: 0;
    pointer-events: none;
    transform: translateY(-100%);
  }
  .mobile-img {
    max-width: 280px;
    margin: 0 auto 7px;
    position: relative;
    left: 6px;
  }
  .banner-title {
    font-size: 38px;
  }
}

